import { useShow, IResourceComponentsProps, useOne, useNavigation, useList, useGetIdentity } from "@pankod/refine-core";

import { Show, Typography, MarkdownField, Modal, ModalProps, FormProps, Form, Icons } from "@pankod/refine-antd";
import moment from 'moment-timezone';
import { ICategory, IProduct } from "interfaces";
import { PictureOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from 'react';
import "./products.css"
import { GET_ALL_PRODUCTS, GET_PRODUCT_REVIEWS } from "query";
import { useParams } from "react-router-dom";

// type ShowProductProps = {
//     closeShowModal:  () => void;
//     showModalVisible: boolean;
//     modalProps: ModalProps;
//     formProps: FormProps;
// }
interface ShowProductProps<TCrudData = any, TOptionsPropsExtends = {
    [key: string]: any;
}> {
    canCreate?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    canShow?: boolean;
    name?: string;
    initialData?: TCrudData;
    // options?: OptionsProps<TOptionsPropsExtends>;
    closeShowModal:  () => void;
    showModalVisible: boolean;
    modalProps: ModalProps;
    formProps: FormProps;
}

const { Title, Text, Paragraph } = Typography;

export const ProductShow: React.FC<ShowProductProps> =
    ({
        closeShowModal,
        showModalVisible,
        modalProps,
        formProps
    }) => {
        const { id } = useParams()
        const { push } = useNavigation();
        const { data: user } = useGetIdentity();
        const userId = user?.metadata?.org_id;
        const [products, setProducts] = useState<any>()
        const [isLoading, setIsLoading] = useState<any>(true)
        const [productReviews, setProductReviews] = useState<any>([])
        const [averageRating, setAverageRating] = useState<any>(0);
        const [totalRatings, setTotalRatings] = useState<any>(0);
        const { ArrowLeftOutlined } = Icons
        const { queryResult } = useShow<IProduct>({
            resource: "products",
            metaData: {
                fields: ["id", "name", "code", "description", 'created_at', 'category_id', "image", "temp_img"],
            },
        });
        const { data, isLoading: prodLoading } = queryResult;
        
        // const record = data?.data;

        // const getProducts = () => {
        //     GET_ALL_PRODUCTS()
        //         .then((data: any) => {
        //             setProducts(() => []);
        //             const prod = data?.products?.find((data: any) => data?.id == id)
        //             setProducts(prod);
        //             setIsLoading(false);
        //         }).catch((error: any) => {
        //             console.log(error, "error");
        //         })
        // }
    const getProductReviews = () => {
        GET_PRODUCT_REVIEWS(userId).then((res:any) => {            
            setProductReviews(res?.product_reviews)
        })
    }

    useEffect(() => {
        getProductReviews();
    },[userId])
        useEffect(() => {
            // getProducts();
            
            setProducts(formProps?.initialValues)
            setIsLoading(false);
            const filterRatings = productReviews?.filter((data:any) => {
               return data?.product_id == formProps?.initialValues?.id
            })
            if(filterRatings){
                const total = filterRatings.length;
                const sum = filterRatings.reduce((acc:any, rating:any) => acc + rating?.rating, 0);
                const average = total > 0 ? sum / total : 0;
        
                setAverageRating(average);
                setTotalRatings(total);
            }
        }, [formProps?.initialValues])


        const { data: categoryData, isLoading: categoryIsLoading } =
            useOne<ICategory>({
                resource: "categories",
                id: products?.category_id || "",
                queryOptions: {
                    enabled: !!products,
                },
                metaData: {
                    fields: ["id", "name"],
                },
            });

        let isoDate = products?.created_at;
        let newDate = moment.utc(isoDate).format('MM/DD/YY');
        const goBack = () => {
            window.history.back();
          };

          const renderStarRating = (rating:any) => {
            const filledStarsCount = Math.round(rating);
            const emptyStarsCount = 5 - filledStarsCount;
          
            const filledStars = Array.from({ length: filledStarsCount }, (_, index) => (
              <span key={index + 1} style={{color: "#EAB308"}}>★</span>
            ));
          
            const emptyStars = Array.from({ length: emptyStarsCount }, (_, index) => (
              <span key={filledStarsCount + index + 1} style={{color: "gray"}}>★</span>
            ));
          
            return [...filledStars, ...emptyStars];
          };

        return (
            <div className="show_product_page">
                <Modal {...modalProps}
                title={false}
                //  title="View Product"
                onCancel={closeShowModal}
                visible={showModalVisible}
              >
                {/* <div
                style={{cursor: "pointer"}}
                onClick={() => goBack()}
                >
                    <ArrowLeftOutlined 
                    className="product_back_button"
                    />
                </div> */}
                 <Show isLoading={isLoading} title="View Product" pageHeaderProps={{ style: {} }}>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div>
                                <Title className="Title_headings" level={5}>Product Name</Title>
                                <Text>{products?.name}</Text>

                                <Title className="Title_headings" level={5}>Code</Title>
                                <Text>{products?.code}</Text>

                                <Title className="Title_headings" level={5}>Id</Title>
                                <Text>{products?.id}</Text>

                                <Title className="Title_headings" level={5}>Description</Title>
                                <Text>{products?.description}</Text>

                                <Title style={{ color: "#4EA144" }} level={5}>Created At</Title>
                                <Text>{newDate}</Text>

                                <Title className="Title_headings" level={5}>Category</Title>
                                <Text>
                                    {categoryIsLoading ? "Loading..." : categoryData?.data.name}
                                </Text>

                                <Title className="Title_headings" level={5}>Rating</Title>
                                <Text> Average Rating: {renderStarRating(averageRating)}</Text>
                                <Paragraph> Total Ratings: {totalRatings}</Paragraph>

                            </div>
                            <div>
                                <Title className="Title_headings" level={5}>Image</Title>
                                <Text>
                                    {products?.image
                                        ? <img style={{ width: "300px", height: "180px" }} src={products?.image} />
                                        : <img style={{ width: "300px", height: "180px" }} src={"/images/product.png"} />
                                    }
                                </Text>
                                <Title className="Title_headings" level={5}>Thumbnail Image</Title>
                                <Text>
                                    {products?.temp_img
                                        ? <img style={{ width: "300px", height: "180px" }} src={products?.temp_img} />
                                        : <img style={{ width: "300px", height: "180px" }} src={"/images/product.png"} />
                                    }
                                </Text>
                            </div>
                            <div>
      {/* Other product details */}
    </div>
                        </div>
                    {/* <Title level={5}>Content</Title>
            <MarkdownField value={products?.content} /> */}
                </Show>
             </Modal>
                </div>
        );
    };
