import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  useForm,
} from "@pankod/refine-antd";
import { useNotification } from "@pankod/refine-core";
import { GET_ALL_PRODUCTS, GET_TBC_ITEMS } from "query";
import React, { useEffect, useState } from "react";
import { nhost } from "utility";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { rejects } from "assert";

const { Option } = Select;

export const CreateTBC: React.FC<any> = ({
  modalProps,
  formProps,
  record,
  closeModal,
}) => {
  
  const [itemList, setItemList] = useState<any>([]);
  const [itemBucket, setItemBucket] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [editFlag, setEditFlag] = useState<any>(null);
  const User: any = nhost.auth.getUser();
  const orgId = User?.metadata?.org_id;
  const [form] = Form.useForm<any>();
  const { open, close } = useNotification();
  const [productsSelecter, setProductsSelecter] = useState<any>([])

  useEffect(()=>{
    setItemBucket([]);
    setItemList([]);
  },[])

  const handleProductSearch = (productsearch: string) => {
    
    if (productsearch?.length > 0) {
      const filterProduct = products?.filter((prod: any) => {
        return prod?.name
          ?.toLowerCase()
          .includes(productsearch?.toLocaleLowerCase());
      });
      if (filterProduct?.length != 0) {
        setProducts([...filterProduct]);
      } else {
        setProducts([])
      }
    } else {
      if(itemBucket?.length <= 0){
        getProducts();
      }else{
        getFilterProducts(itemBucket);
      }
    }
  };

  const getProducts = () => {
    
    GET_ALL_PRODUCTS(orgId)
      .then((data: any) => {
        setProducts(() => []);
        setProducts(data?.products);
        setItemList(data?.products);
        // setIsLoading(false);
      })
      .catch((error: any) => {
      });
  };

  const getFilterProducts = async(productBag: any) =>{
    let respose = await GET_ALL_PRODUCTS(orgId)
    .then((data: any) => {
      let filteredProducts = data?.products?.filter((product: any) =>
        !productBag.some((bagItem: any) => bagItem.id === product.id)
      );
      setProducts(filteredProducts);
      setItemList(filteredProducts);
    })
    .catch((error: any) => {
    });
  }

  const getTbc = async () => {
    let respose = await GET_TBC_ITEMS(record).then((res: any) => {
      if (res?.to_be_charged?.length !== 0 &&  res?.to_be_charged !== undefined) {
        getFilterProducts(res?.to_be_charged[0]?.items)
        setItemBucket(res?.to_be_charged[0]?.items);
        setEditFlag(res?.to_be_charged[0]);
      }else{
        setEditFlag(null)
        getProducts() // call the all the product while basket is empty
        setItemBucket([])
      }
    });
  };

  useEffect(() => {
    getTbc();
  }, [record, modalProps]);

  useEffect(() => {
    if (products && editFlag) {
      let idToRemove = itemBucket?.map((data: any) => data.id);
      let filterProduct = products?.filter(
        (data: any) => !idToRemove?.includes(data?.id)
      );
      setProducts(filterProduct);
    }
  }, [editFlag]);
  

  const addProduct = (value: any) => {
    
    let addProduct = form.getFieldsValue();
    
    let selectedProduct = itemList?.filter(
      (record: any) => record?.id === addProduct?.product
    );
    let deleteProduct = products?.filter(
      (data: any) => data?.id !== selectedProduct[0]?.id
    );
    
    selectedProduct[0].cartQuantity = addProduct?.quantity || 1;
    setItemBucket([...itemBucket, ...selectedProduct]);
    setProducts(deleteProduct);
    form.resetFields();
  };

  const deleteSelectedProduct = (data: any, index: any) => {
    let deleteProduct = itemBucket?.filter(
      (record: any) => record.id !== data.id
    );
    setProducts([...products, data]);
    setItemBucket(deleteProduct);
  };

  const handleApi = () => {
    if (editFlag) {
      let mutation = `mutation MyMutation($id: uuid, $items: jsonb, $updated_by: uuid) {
        update_to_be_charged(where: {id: {_eq: $id}}, _set: {items: $items, updated_by: $updated_by}) {
          affected_rows
        }
      }
    `;

    let variables = {
      id: editFlag?.id,
      updated_by: User?.id,
      items: itemBucket,
    };

    nhost.graphql
    .request(mutation, variables)
    .then((res: any) => {
      if (res?.error === null) {
        handelClose();
        open?.({
          key: "tbc_success",
          type: "success",
          message: "",
          description: `TBC Product Updated Successfully.`,
        });
        setTimeout(() => {
          close("tbc_success");
        }, 2000);
      }
    })
    .catch((error: any) => {
    });
    } else {
      
      let mutation = `
      mutation MyMutation($customerId: uuid, $createdBy: uuid, $items: jsonb, $org_id: uuid) {
        insert_to_be_charged(
          objects: {
            customer_id: $customerId
            created_by: $createdBy
            items: $items
            org_id: $org_id
          }
        ) {
          affected_rows
          returning {
            id
          }
        }
      }
      `;
      let mutationCustomer = `mutation MyMutation($id: uuid, $tbcId: uuid) {
        update_customers(where: {id: {_eq: $id}}, _set: {to_be_charged_id: $tbcId}) {
          affected_rows
        }
      }
     `;

      let variables = {
        customerId: record?.id,
        createdBy: User?.id,
        items: itemBucket,
        org_id:orgId
      };

      nhost.graphql
      .request(mutation, variables)
      .then((res: any) => {
        if (res?.error) {
        } else {
          let variables = {
            id: record?.id,
            tbcId: res?.data?.insert_to_be_charged?.returning?.[0]?.id
          };
          
          nhost.graphql.request(mutationCustomer, variables).then((res: any) =>{
           
            if(res?.error){
              
            }else{
              open?.({
                key: "tbc_success",
                type: "success",
                message: "",
                description: `TBC Product Add Successfully.`,
              });
              setTimeout(() => {
                close("tbc_success");
              }, 2000);
              handelClose()
            }
          })
        }
      })
      .catch((error: any) => {
      });
    }
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(itemBucket)
    const indexOfItem = items.map((data: any, index: number) => data?.id)
    let sourceIndex = indexOfItem.findIndex((data: any)=>data === result.source.index)
    let destinationIndex = indexOfItem.findIndex((data: any)=>data === result.destination.index)
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);
    setItemBucket(items);
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Quantity",
      dataIndex: "cartQuantity",
    },
    {
      title: "Action",
      render: (text: any, record: any, index: any) => {
        return (
          <div>
            <Button
              onClick={() => deleteSelectedProduct(text, index)}
              style={{ color: "red", outline: "none", border: "none" }}
              icon={<DeleteOutlined />}
            ></Button>
          </div>
        );
      },
    },
  ];

  const handelClose = () =>{ 
    setItemBucket([])
    setProducts([])
    setItemList([])
    form.resetFields()
    closeModal()
  }
  
  const validationQuantity =(_: any, value: string,  callback: (error?: string) => void)=>{
    let eventQuantity = Number(value)
    if(!value){
      callback("Pleas enter the product quantity")
    }else if(eventQuantity < productsSelecter?.min){
      callback(`You should add minimum ${productsSelecter?.min} quantity`)
    }else{
      callback()
    }
  }

  const handelProduct = (e: React.ChangeEvent<HTMLAnchorElement>)=>{
    let selecterProduct = itemList?.filter((item: any, index: number) =>item?.id === e)
    setProductsSelecter({min:selecterProduct[0].minimum_quantity, max:selecterProduct[0].maximum_quantity, total_quantiy: selecterProduct[0].quantity})
  }

  return (
    <Modal
      {...modalProps}
      title={"TBC Items"}
      okButtonProps={handleApi}
      onOk={handleApi}
      onCancel={handelClose}
    >
      <Form {...formProps} layout="vertical" form={form} onFinish={addProduct}>
        <Row gutter={{ xs: 32, sm: 32, md: 32, lg: 32 }}>
          <Col span={8}>
            <Form.Item 
              label={"Product"}
              name={"product"} 
              required = {false}
              rules={[{ required: true, message: "Pleas select the product" }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Please select"
                onChange={handelProduct}
                onSearch={handleProductSearch}
                filterOption={false}
              >
                {[...products]?.map((e: any, i: any) => {
                  return <Option key={e?.id}>{e?.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label={"Quantity"} 
              required = {false}
              name={"quantity"}
              rules={[{validator: validationQuantity}]}
            >
              <Input type="number" min={0}/>
            </Form.Item>
          </Col>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "16px" }}
          >
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Form.Item>
          </div>
        </Row>
        {/* <Row> */}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="itemBucket">
          {(provided) => (
            <Table 
              {...provided.droppableProps}
              columns={columns} 
              dataSource={itemBucket}  
              ref={provided.innerRef}
              rowKey="id"
              components = {{
                body: {
                  row: (props: any) => {
                    
                    return(
                    <Draggable
                        draggableId={props["data-row-key"]}
                        index={props["data-row-key"]}
                      >
                        {(provided) => {
                          
                          return(
                          <tr
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            {props.children}
                          </tr>
                        )}}
                      </Draggable>
                  )}
                }
              }}
              pagination = {false}

            />
          )}
          </Droppable>
        </DragDropContext>
        {/* </Row> */}
      </Form>
    </Modal>
  );
};
