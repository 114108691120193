import { ArrowUpOutlined } from "@ant-design/icons";
import { useDashboardContext } from "contaxt/dashboardContext";
import CountUp from "react-countup";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

export const OrderTrendChart = () => {
  
  const {orderTrends, orderCount, totalRevenue, filterDate, aov} = useDashboardContext()
  const middleIndex = Math.floor(orderTrends.length / 2);
  const middleDate = orderTrends[middleIndex]?.date;

  let filterStates = filterDate == null ? true : false

  const CustomLegend = (props: any)=>{
    const {payload} = props 
    return(
      <div className="legend_continer">
        <div className="legend_box">
          {payload?.map((item: any)=>{
            return(
              <div className="legend_card">
                <div className="legend_card_body">
                  <div className="legend_indicator">
                    <span className="indicator_color" style={{backgroundColor: `${item?.color}`}}></span>
                    <p className="indicator_txt">{item?.dataKey}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="continer">
      <div className="analysis_card">
        <div className="analysis_card-header">
          <div className="card_title">
            <h4>Order Trends</h4>
          </div>
        </div>
        <div className="analysis_card-body">
          <div className="analysis_report_box">
            <div className="report_card">
              <div className="report_title">
                <p>Total Order</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={orderCount}
                      duration={2.6}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Total Revenue</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={totalRevenue}
                      prefix="£ "
                      duration={2.75}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Average Order Value</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                <div className="report_value_section">
                  <CountUp
                    start={0}
                    end={aov}
                    prefix="£"
                    duration={2.75}
                    className="countup_count"
                  />
                  <ArrowUpOutlined className="arrow" />
                  <p className="report_rate">0.0%</p>
                </div>
                {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Order Growth Rate</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={0}
                      duration={2.75}
                      suffix="%"
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Return Rate</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={0}
                      suffix="%"
                      duration={2.75}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="analysis_chart">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={orderTrends}
              >
                <CartesianGrid   stroke="#DDD"  vertical={false}/>
                <XAxis width={200} dataKey="date" axisLine={false} tick={{ fontSize: 12, fill: '#AAA7AD' }} tickLine={{ stroke: "none" }}/>
                <YAxis axisLine={false} tick={{ fontSize: 12, fill: '#AAA7AD' }} tickLine={{ stroke: 'none' }} tickFormatter={(value) => `£ ${value}`}/>
                <Tooltip content={<CustomTooltip/>}/>
                <ReferenceLine x={middleDate} stroke="#DDD" />
                <Legend content={<CustomLegend/>}/>
                <Line
                  type="linear"
                  dataKey="orderCount"
                  stroke="#82ca9d"
                  dot={false}
                  animationDuration={1500}
                  animationEasing="ease-out"
                  isAnimationActive={false}
                />
                <Line
                  type="linear"
                  dataKey="revenue"
                  stroke="#ff7310"
                  dot={false}
                  animationDuration={1500}
                  animationEasing="ease-out"
                  isAnimationActive={false}
                />
                {/* <Line type="linear" dataKey="AOV" stroke="#ff7310" dot = {false} /> */}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
  
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip_continer">
        <div className="tooltip_header">
          <p>{`${label}`}</p>
        </div>
        <div className="tooltip_body">
          {payload.map((entry, index) => (
            <div className="tooltip_content">
              <div>
                <span style={{backgroundColor: entry.stroke}} className="tooltip_indicator"></span>
              </div>
              <div className="tooltip_indicator-box">
                <p className="tooltip_indicator-lable">{entry.name}: </p>
                <p className="tooltip_indicator-lable"> {entry.name !== "orderCount" && "£"} {entry.name === "revenue" ?entry.value?.toFixed(2):entry.value}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};
