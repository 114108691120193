import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Checkbox,
  InputNumber, Select, useSelect, Button, Icon
} from "@pankod/refine-antd";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import { IPrice, IOrg, ICategory, ICurrency } from "interfaces";
import { GET_CURRENCY } from '../../../query';
import React, { useState, useEffect } from 'react';
import * as XLSX from "xlsx";
import { nhost } from "utility";
import { jsonConverter } from "components/helpers/jsonConverter";
import "./style.css"

// var Currencydata = require('currency-codes/data');

type CreateProductProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  openCreateModal: boolean;
  closeCreateModal: () => void;
};

export const CreateProduct: React.FC<CreateProductProps> = ({
  modalProps,
  formProps,
  openCreateModal,
  closeCreateModal,
}) => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();
  const [privacy, setPrivacy] = useState(false)
  const [userList, setUserList] = useState([] as any[]);
  const [saveDisable, setSaveDisable] = useState(false)
  const [productPrice, setProductPrice] = useState<any>(null)
  const [excelFile_Ids, setExcelFile_Ids] = useState<any>(null)

  // let currencyCodes = Currencydata;
  const { selectProps: OBProductSelectProps } = useSelect<IPrice>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  // const { selectProps: OBCurrencyProps } = useSelect<ICurrency>({
  //   resource: "currency",
  //   fetchSize: 1000,
  //   optionLabel: "name",
  //   optionValue: "id",
  //   metaData: {
  //     fields: ["id", "name"],
  //   },
  //   sort: [{ field: "name", order: "asc", },]
  // });

  // const { selectProps: categorySelectProps } = useSelect<ICategory>({
  //   resource: "categories",
  //   fetchSize: 1000,
  //   optionLabel: "name",
  //   optionValue: "id",
  //   metaData: {
  //     fields: ["id", "name"],
  //   },
  //   sort: [{ field: "name", order: "asc", },]
  // });

  const getUsers = async () => {
    GET_CURRENCY().then((response: any) => {
      setUserList(response.currency);
    });
  };
  useEffect(() => {
    getUsers();
  }, []);

  const onChangeCheckboxPrivacy = (e: any) => {
    let checkbox = e.target.checked
    setPrivacy(checkbox)
  };

  const handleUpload = (e: any) => {
    setSaveDisable(true)
    let fileContent: any = e.target.files[0]
    if (fileContent) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const fileContent = e.target.result;
        const workbook = XLSX.read(fileContent, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        let excelHead: any = jsonData[1]
        jsonData?.splice(0, 2)
        // const headings:any = jsonData[1];
        //     const result:any = [];
        //     for (let i = 2; i < jsonData.length; i++) {
        //         const row:any = jsonData[i];
        //         const obj:any = {};
        //         for (let j = 0; j < headings.length; j++) {
        //             obj[headings[j]] = row[j];
        //         }
        //         result.push(obj);
        //     }
        //     console.log(result, "sdfsfsfsfsdfsdf");
        // let productIds = jsonData.map((id: any) => `"${id[3]}"`).join(",")

        let QUERY = `query MyQuery {
    products(where: {ob_org_id: {_eq: "${user?.metadata?.org_id}"}, code: {_in: [${jsonData.map((code: any) => `"${code[3]}"`).join(",")}]}}) {
      customer_price
      code
    }
  }`;
        const callQuery = async () => {
          const { data: prod }: any = await nhost.graphql.request(QUERY);
          if(prod?.products?.length > 0){
            setSaveDisable(false)
          }
          setProductPrice(prod?.products)
        }
        callQuery();
        setExcelFile_Ids(jsonData)

      };
      reader.readAsBinaryString(fileContent);
    }
  };


  const onFinish = (e: any) => {
    e.created_by = user?.id ? user?.id : null;
    e.ob_org_id = user?.metadata?.org_id ? user?.metadata?.org_id : null;
    e.default = e.default != undefined ? e.default : false;

    let createPriceList = `mutation MyMutation {
      insert_pricelists(objects: {
      created_by: "${e.created_by}", 
      default: ${e.default}, 
      currency: "${e.currency}",
      name: "${e.name}", 
      ob_org_id: "${e.ob_org_id}",
      short_name: "${e.short_name}",
    }) {
        affected_rows
        returning {
          id
          name
        }
      }
    }`;

    const updateProductPrice = `mutation UpdateProductPrice($code: String!, $customerPrice: jsonb!) {
      update_products(
        where: { code: { _eq: $code } }
        _set: { customer_price: $customerPrice }
      ) {
        affected_rows
      }
    }`;

    if(productPrice?.length > 0 ){
    const updateCustomerPrice = async () => {
      console.log(excelFile_Ids, "exceldata", "consoleconsoleconsole");
      console.log(productPrice, "apidata", "consoleconsoleconsole");

      const mergedArray = productPrice.map(async (item: any) => {

        const matchingItem = excelFile_Ids.find((arr: any) => arr[3] === item.code);
        if (matchingItem) {

          const findAllEqual = item?.customer_price?.find((price: any) =>
            matchingItem[2] === price?.PRICING_REF &&
            matchingItem[6] === price?.STORED_PRICE &&
            matchingItem[1] === price?.TYPE &&
            matchingItem[0] === price?.PRICE_ID &&
            matchingItem[5] === price?.CALC_VALUE &&
            matchingItem[4] === price?.CALC_METHOD);
          console.log(findAllEqual, "findAllEqual");

          if (findAllEqual) {

            console.log("nochange", "consoleconsoleconsole");

          } else {

            console.log("change in value", "consoleconsoleconsole");

            const findRef = item?.customer_price?.find((price: any) => matchingItem[2] === price?.PRICING_REF && matchingItem[6] !== price?.STORED_PRICE);

            if (findRef) {
              console.log("no new array created", "Price Ref changes", "consoleconsoleconsole");

              item.customer_price = item?.customer_price?.map((data: any) => {

                if (matchingItem[2] == data?.PRICING_REF) {
                  console.log(data, 'checkupdatevalueornot', "consoleconsoleconsole");

                  console.log(matchingItem[6], 'checkupdatevalueornot');

                  if (data?.STORED_PRICE !== matchingItem[6]) {
                    data.STORED_PRICE = matchingItem[6]
                  }
                  if (data?.TYPE !== matchingItem[1]) {
                    data.TYPE = matchingItem[1]
                  }
                  if (data?.PRICE_ID !== matchingItem[0]) {
                    data.PRICE_ID = matchingItem[0]
                  }
                  if (data?.CALC_VALUE !== matchingItem[5]) {
                    data.CALC_VALUE = matchingItem[5]
                  }
                  if (data?.CALC_METHOD !== matchingItem[4]) {
                    data.CALC_METHOD = matchingItem[4]
                  }
                  if (data?.PRICING_REF !== matchingItem[2]) {
                    data.PRICING_REF = matchingItem[2]
                  }
                }

                return data
              })
            } else {
              console.log("Create new array", "Price Ref changes", "consoleconsoleconsole");

              let olddata = item.customer_price ? item.customer_price : []

              item.customer_price = [
                ...olddata,
                {
                  TYPE: matchingItem[1],
                  PRICE_ID: matchingItem[0],
                  CALC_VALUE: matchingItem[5],
                  CALC_METHOD: matchingItem[4],
                  PRICING_REF: matchingItem[2],
                  STORED_PRICE: matchingItem[6]
                }
              ]
            }
          }
        }

        const variables = {
          code: item?.code,
          customerPrice: item?.customer_price
        };

        const { data: result } = await nhost.graphql.request(updateProductPrice, variables);

        console.log(item, "Final updated VAlue", "consoleconsoleconsole");

        return item;
      });

    };

    updateCustomerPrice();
  }

    const querySubmit = async () => {
      const { data } = await nhost.graphql.request(createPriceList);

      if (data) {
        open?.({
          key:"success",
          type: "success",
          message: "",
          description: `Pricelist Sucessfully Created`,
      });
        setTimeout(() => {
          close("success");
        }, 4000);
        closeCreateModal();
        setProductPrice(null);
        setExcelFile_Ids(null);
      }
    }
    querySubmit();
  }
  return (
    <Modal {...modalProps} 
    title="Create Price List"
    onCancel={closeCreateModal}
    visible={openCreateModal}
    footer={false}
    >
      <Form
        {...formProps} size="large" layout="vertical"
        onFinish={(e: any) => onFinish(e)}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Short Name"
          name="short_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {user?.defaultRole === "admin" ?
          <Form.Item
            label="Orderbit Organisation"
            name="ob_org_id"
            rules={[{ required: true, }]} >
            <Select {...OBOrgSelectProps} />
          </Form.Item> :
          <Form.Item
            name="ob_org_id"
            initialValue={user?.metadata?.org_id}
          >
            <Input hidden />
          </Form.Item>
        }

        <Form.Item name="default" valuePropName="checked" style={{ marginTop: '-30px' }}
        // wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>is default?</Checkbox>
        </Form.Item>
        {/* <Checkbox >is default?</Checkbox> */}

        {/* <Form.Item
              label="Category"
              name="category_id"
              rules={[{ required: true, }]} >
              <Select {...categorySelectProps} />
            </Form.Item> */}
        <Form.Item
          label="Currency"
          name="currency"
          rules={[{ required: true, }]} >
          <Select>
            <Select.Option name="Pounds" value="Pounds" >Pounds</Select.Option>
            {/* {
              currencyCodes.map((data: any) => {
                return <Select.Option value={data?.currency} name="currency">
                  {data?.currency}
                </Select.Option>
              })
            } */}
          </Select>
        </Form.Item >
        {/* <Form.Item
          label="Based On"
          name="basedon"
          rules={[{ required: true, }]} >
          <Select {...OBOrgSelectProps} /> 
          <Select>
            <Select.Option name="basedon" value="Basic Price List" >Basic Price List</Select.Option>
            <Select.Option name="basedon" value="VIP Price List" >VIP Price List</Select.Option>
            <Select.Option name="basedon" value="Wholesale Price List">Wholesale Price List</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="discount_percentage"
          label="Discount Percentage"

          rules={[{ required: true, message: 'Please input your discout!' }]}
        >
          <InputNumber defaultValue="0.0" style={{ width: "50%" }} name="discount_percentage" />
        </Form.Item> */}
        <Form.Item name="created_by"
          initialValue={user?.id}
        >
          <Input type="hidden" />
        </Form.Item>
        {/* <label htmlFor="fileInput" className="upload-label">Upload</label>
        <input
          type="file"
          id="fileInput"
          accept=".csv, .xlsx"
          onChange={(e: any) => {
            handleUpload(e);
            e.target.value = "";
          }}
          style={{ display: "none" }}
        ></input> */}
         <div style={{display: "flex", justifyContent: "end"}}>
          <Button style={{marginInlineEnd: "10px"}} onClick={closeCreateModal} type="default">
          Cancel
        </Button>
          <Button style={{marginInlineStart: "10px"}} type="primary" htmlType="submit"
          disabled={saveDisable}
          >
          Save
        </Button>
        </div>
      </Form >
    </Modal >
  );
};
