import { FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select, useSelect, useTable } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IOrderStatus } from "interfaces";
import moment from "moment";
import { GET_ORDER_BY_CATEGORIE, GET_ORDERS_DASHBOARD, GET_PRODUCT_BY_CATEGORIE } from "query";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { FilterDate } from "utility/filterDate";

type MenuContextType = {
    // menuPermission: boolean;
    // setPermissions: (permissions: boolean) => void;
    date: any;
    filterDate: any;
    stackedOrder: any;
    orderTrends:any;
    orderData:any;
    orderCount: number;
    totalRevenue: number;
    topSalesTabale: any;
    orderCustomersCard: any;
    orderCustomers: any;
    aov:number;
    getDatesArray: (startDateStr: string, endDateStr: string) => string[];
};

const DashboardContext = createContext<MenuContextType | undefined>(undefined);

const { RangePicker } = DatePicker;

export const DashBoardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const maxOrder: number = 5;
    const maxOrderPrice: number = 500;
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const [orderCount, setOrderCount] = useState<number>(0);
    const [totalRevenue, setTotalRevenue] = useState<number>(0);
    const [aov, setAov] = useState<number>(0);
    const [orderTrends, setOrderTrends] = useState<any>([]);
    const [stackedOrder, setStackedOrder] = useState<any>([]);
    const [orderData, setOrderData] = useState<any>([]);
    const [date, setDate] = useState<any>(null);
    const [productCategories, setProductCategory] = useState(null);
    const [topSalesTabale, setTopSalesTable] = useState<any>([]);
    const [orderCustomersCard, setOrderCustomersCard] = useState<any>(null);
    const [orderCustomers, setOrderCustomers] = useState<any>([]);
    const [orderCustomersChart, setOrderCustomersChart] = useState<any>([]);
    const [filterDate, setFilterDate] = useState<any>(null);

    useEffect( ()=>{
        let date: any = FilterDate("CURRENT_WEEK")
        // let perviewDate: any = FilterDate("PREVIEWS_WEEK")
        // console.log(perviewDate, "PRVIEWS_WEEK");
        
        
        if(user){
          let payload = {
            org_id: userId,
            startDate: moment(date?.startDate).format("YYYY/MM/DD"),
            endDate: moment(date?.endDate).format("YYYY/MM/DD")
          }
          orderDashboard(payload)
        }
    },[user])

    const { selectProps: categorySelectProps } = useSelect<any>({
        resource: "categories",
        filters: [
          {
            field: "is_active",
            operator: "eq",
            value: true,
          },
          {
            field: "ob_org_id",
            operator: "eq",
            value: userId,
          },
        ],
        metaData: {
          fields: ["id", "name"],
        },
        optionLabel: "name",
        optionValue: "id",
    
        onSearch: (value) => [
          {
            field: "name",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
    });
    
    const { selectProps: customerSelectProps } = useSelect<any>({
        resource: "customers",
        filters: [
          {
            field: "is_active",
            operator: "eq",
            value: true,
          },
          {
            field: "ob_org_id",
            operator: "eq",
            value: userId,
          },
        ],
        metaData: {
          fields: ["id", "name"],
        },
        optionLabel: "name",
        optionValue: "id",
    
        onSearch: (value) => [
          {
            field: "name",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
    });

    const tableData = useTable<IOrderStatus>({
        resource: "order_statuses",
    
        queryOptions: {
          staleTime: 0,
          cacheTime: 0,
        },
    
        initialSorter: [
          {
            field: "created_at",
            order: "desc",
          },
        ],
        permanentFilter: [
          {
            field: "ob_org_id",
            operator: "eq",
            value: userId,
          },
        ],
        metaData: {
          fields: [
            "id",
            "name",
            "sort",
            "bg_color",
            "ob_org_id",
            "notify_customer",
          ],
        },
      });

      const handleRangeDate = (dates: any, dateStrings: any) => {
        if (dates) {
          let startDate = moment(dateStrings[0]);
          let endDate = moment(dateStrings[1]).add(1, 'days');
          setFilterDate({
            startDate: startDate.format("YYYY/MM/DD HH:mm:ss"),
            endDate: endDate.format("YYYY/MM/DD HH:mm:ss"),
          })
          let filterDate = {
            startDate: startDate.format("YYYY/MM/DD HH:mm:ss"),
            endDate: endDate.format("YYYY/MM/DD HH:mm:ss"), // Ensure to format it properly
            org_id: userId
          };
      
          orderDashboard(filterDate);
        } else {
          let date: any = FilterDate("CURRENT_WEEK");
          let payload = {
            org_id: userId,
            startDate: moment(date?.startDate).format("YYYY/MM/DD"),
            endDate: moment(date?.endDate).format("YYYY/MM/DD")
          };
          setFilterDate(null)
          orderDashboard(payload);
        }
      };
      

    const orderDashboard = async(param: any)=>{
      let storeDate = {
        "start_date": param.startDate,
        "end_date": param.endDate
      }
      
      const response = await GET_ORDERS_DASHBOARD(param).then((res: any)=>{
        setOrderData(res?.orders_aggregate);
        setOrderCount(res?.orders_aggregate?.aggregate?.count);
        setTotalRevenue(res?.orders_aggregate?.aggregate?.sum?.total_price);
        setAov(res?.orders_aggregate?.aggregate?.avg?.total_price);
        setDate(storeDate);
        conventChartData(res?.orders_aggregate?.nodes, param);
        stackedChart(res?.orders_aggregate?.nodes, param);
        topSalesFilter(res?.orders_aggregate?.nodes);
        highOrderLowOrderCustomer(res?.orders_aggregate?.nodes, param);
      })
    }

    const conventChartData = (data: any, param: any) =>{
      let dateList = getDatesArray(param?.startDate,param?.endDate)
      let conventChart =dateList.map((date: string)=>{
        let filterData = data?.filter((param: any)=>moment(param?.created_at).format("YYYY/MM/DD") == date)
        let revenue = filterData?.reduce((total: number,param: any)=>total+param?.total_price, 0 )
        let aov = revenue/filterData?.length
        let formate = {
          date:moment(date).format("MMM DD"),
          "orderCount": filterData?.length || 0,
          "orderLine": filterData?.length || 0,
          revenue: revenue || 0,
          AOV: aov || 0
        }
        return formate
      })
      setOrderTrends(conventChart)
    }

    const stackedChart=(data: any, param: any)=>{
      let Completed = data?.filter((param: any)=>param?.status == "Completed")
      let pending = data?.filter((param: any)=>param?.status == "Preparing")
      let Cancelled = data?.filter((param: any)=>param?.status == "Cancelled")
  
        let formatData = [
          {name:"Completed", value: Completed?.length > 0 ? Completed?.length : Completed?.length },
          {name:"Pending", value: pending?.length > 0 ? pending?.length : pending?.length },
          {name:"Cancelled", value: Cancelled?.length > 0 ? Cancelled?.length  : Cancelled?.length },
        ]
      setStackedOrder(formatData)
    }

    const topSalesFilter = (data: any[]) => {
      let itemFilter: any[] = [];
      let topItemMap: { [key: string]: any } = {};
    
      data?.forEach((order: any) => {
        itemFilter.push(...order?.items);
      });
    
      itemFilter?.forEach((item: any) => {
        if (topItemMap[item.productId]) {
          topItemMap[item.productId].revenue += item.price * item.quantity;
          topItemMap[item.productId].quantity += Number(item.quantity);
          topItemMap[item.productId].orderCount += 1;
        } else {
          topItemMap[item.productId] = {
            id: item.productId,
            name: item.name,
            revenue: item.price * item.quantity,
            quantity:  Number(item.quantity),
            orderCount: 1,
          };
        }
      });
    
      const topItem = Object.values(topItemMap);
      topItem.sort((a: any, b: any) => b.orderCount - a.orderCount);
      setTopSalesTable( topItem);
    };

    //  High Order vs. Low Order Customers function
    const highOrderLowOrderCustomer = (data: any, param: any)=>{
      let customerFilter: any[] = [];
      let customerMap: { [key: string]: any } = {};
    
      data?.forEach((order: any) => {
        customerFilter.push(order);
      });
    
      customerFilter?.forEach((item: any) => {
        if (customerMap[item.customer_id]) {
          customerMap[item.customer_id].revenue += item.total_price;
          customerMap[item.customer_id].orderCount += 1;
        } else 
        {
          customerMap[item.customer_id] = 
          {
            name: item.name,
           "order Type": item.order_Type,
           "orderId": item.order_Unique_id,
            revenue:  Number(item.total_price),
            orderCount: 1
          };
        }
      });

      const allCustomer = Object.values(customerMap);
      allCustomer.sort((a: any, b: any) => b.revenue - a.revenue)

      let highOrderCustomer = allCustomer?.filter((data: any)=> Number(data?.revenue?.toFixed(2)) >= maxOrderPrice);
      let LowOrderCustomer = allCustomer?.filter((data: any)=> Number(data?.revenue?.toFixed(2)) < maxOrderPrice);
      // .reduce((total: number,param: any)=>total+param?.revenue, 0 )
      let totalNoOrder = allCustomer?.reduce((total: number,param: any)=>total+param?.orderCount, 0 );
      let totalHighOrderCustomerRevenue  = highOrderCustomer?.reduce((total: number,param: any)=>total+param?.revenue, 0 );
      let totalHighOrderCustomerOrder  = highOrderCustomer?.reduce((total: number,param: any)=>total+param?.orderCount, 0 );
      let totalLowOrderCustomerRevenue  = LowOrderCustomer?.reduce((total: number,param: any)=>total+param?.revenue, 0 );
      let totalLowOrderCustomerOrder  = LowOrderCustomer?.reduce((total: number,param: any)=>total+param?.orderCount, 0 );
      let ltv_HighOrder =  Number(((totalHighOrderCustomerRevenue/totalHighOrderCustomerOrder) * (totalHighOrderCustomerOrder/highOrderCustomer?.length)).toFixed(2))
      let ltv_LowOrder = Number(((totalLowOrderCustomerRevenue/totalLowOrderCustomerOrder) * (totalLowOrderCustomerOrder/LowOrderCustomer?.length)).toFixed(2))
      let formatCardData = {
        "highOrder":highOrderCustomer?.length,
        "lowOrder":LowOrderCustomer?.length,
        "AOV": highOrderCustomer?.length/LowOrderCustomer?.length,
        LTV: {
          ltv_HighOrder: isNaN(ltv_HighOrder) ? 0 : ltv_HighOrder,
          ltv_LowOrder:isNaN(ltv_LowOrder) ? 0 : ltv_LowOrder,
        }
      }
      setOrderCustomersCard(formatCardData)
      setOrderCustomers(allCustomer)
    }
  
    

    const getDatesArray = (startDateStr: string, endDateStr: string) => {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        const datesArray = [];
        for (let current = startDate; current <= endDate; current.setDate(current.getDate() + 1)) {
          datesArray.push(new Date(current));
        }
        const formatDate = (date: Date) => moment(date).format("YYYY/MM/DD");
      
        return datesArray.map(date => formatDate(date));
    };

    const handleCustomerSearch = async (e: any) => {
        let filterCategorie: any = []
        
        try {
          const res: any = await GET_PRODUCT_BY_CATEGORIE(e);
          const filterProduct = res?.products?.map((proData: any) => proData?.id);
      
          const orderPromises = filterProduct.map(async (key: string) => {
            const orderRes: any = await GET_ORDER_BY_CATEGORIE(key);
            if (orderRes?.orders?.length > 0) {
              return orderRes?.orders;
            }
            return null;
          });
      
          const orderResults = await Promise.all(orderPromises);
          filterCategorie = orderResults.filter(Boolean).flat()
          setProductCategory(e);
      
        } catch (error) {
          // console.error("Error in handleCategorySearch: ", error);
        }
        setProductCategory(e)
    };
    const handleCategorySelecter = async (e: any) => {
        let filterCategorie: any = []
        
        try {
          const res: any = await GET_PRODUCT_BY_CATEGORIE(e);
          const filterProduct = res?.products?.map((proData: any) => proData?.id);
      
          const orderPromises = filterProduct.map(async (key: string) => {
            const orderRes: any = await GET_ORDER_BY_CATEGORIE(key);
            if (orderRes?.orders?.length > 0) {
              return orderRes?.orders;
            }
            return null;
          });
      
          const orderResults = await Promise.all(orderPromises);
          
          filterCategorie = orderResults.filter(Boolean).flat().map((data: any)=>{
            let filterItem = filterProduct.map((data))
          })
          
          setProductCategory(e);
      
        } catch (error) {
          // console.error("Error in handleCategorySearch: ", error);
        }
        setProductCategory(e)
    };

    const handleOnClearSelecter = () => {
    // setProductCategory(null);
    };

    const handleOnClear = () => {
    // setStatus(null);
    };

    const handleSelect = (e: any) => {
    // setStatus(e);
    };
    
    const value = useMemo<any>(()=>{
    return {
        orderTrends,
        orderCount,
        totalRevenue,
        orderData,
        date,
        stackedOrder,
        topSalesTabale,
        aov,
        orderCustomersCard,
        orderCustomers,
        filterDate,
        getDatesArray
    }
    },[orderTrends, orderCount, filterDate, topSalesTabale, totalRevenue, aov, getDatesArray, orderCustomersCard, orderData, setDate, stackedOrder])

    return (
        <>
            <div className="card_action">
                <RangePicker onChange={handleRangeDate} style={{borderColor: "#4EA144"}}/>
                {/* <Select
                    placeholder="Select Customer"
                    onSelect={handleCustomerSearch}
                    clearIcon="*"
                    allowClear
                    onClear={handleOnClearSearch}
                    {...customerSelectProps}
                    className="analysis_select"
                /> */}
                {/* <Button type="primary" icon = {<FilterOutlined />}/>
                <Select
                  placeholder="Select Category"
                  onSelect={handleCategorySelecter}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSelecter}
                  {...categorySelectProps}
                  className="analysis_select"
                /> */}
               {/*  <Select
                    placeholder="Select Product Category"
                    onSelect={handleCategorySearch}
                    clearIcon="*"
                    allowClear
                    onClear={handleOnClearSearch}
                    {...categorySelectProps}
                    className="analysis_select"
                />
                <Select
                    allowClear
                    options={tableData?.tableProps?.dataSource?.map((item: any) => ({
                    value: item?.name,
                    label: item?.name,
                    }))}
                    placeholder="Choose Order Status"
                    onSelect={handleSelect}
                    onClear={handleOnClear}
                    className="analysis_select"
                /> */}
            </div>
            <DashboardContext.Provider value={value}>
                {children}
            </DashboardContext.Provider>
        </>
    );
};

export const useDashboardContext = () => {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error("useContext must be used within an AuthPermissionProvider");
    }
    return context;
};
