import { ArrowUpOutlined } from "@ant-design/icons";
import { Table } from "@pankod/refine-antd";
import { useDashboardContext } from "contaxt/dashboardContext";
import CountUp from "react-countup";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

export const CustomerOrderAnalysis = () => {
  
  const {filterDate, orderCustomers, orderCustomersCard} = useDashboardContext();

  let filterStates = filterDate == null ? true : false;

  const coloums: any = [
    {
        title: "Name",
        dataIndex: "name"
    },
    {
        title: "Order",
        dataIndex: "orderCount",
        sorter: (a: any, b: any) => a.orderCount - b.orderCount,
    },
    {
        dataIndex:"revenue",
        title: "Revenue",
        render:(_: any)=>{
          return(
            <p>£ {_.toFixed(2)}</p>
          )
        },
        sorter: (a: any, b: any) => a.revenue - b.revenue,
    },
  ]
 
  return (
    <div className="continer">
      <div className="analysis_card">
        <div className="analysis_card-header">
          <div className="card_title">
            <h4>High Order and Low Order Customer</h4>
          </div>
        </div>
        <div className="analysis_card-body">
          <div className="analysis_report_box">
            <div className="report_card">
              <div className="report_title">
                <p>High Order Customers</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={orderCustomersCard?.highOrder}
                      duration={2.6}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Low Order Customers</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={isNaN(orderCustomersCard?.lowOrder) ? 0 : orderCustomersCard?.lowOrder}
                      duration={2.75}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Lifetime Value</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={orderCustomersCard?.LTV?.ltv_HighOrder}
                      prefix="£"
                      suffix={` | £${orderCustomersCard?.LTV?.ltv_LowOrder}`}
                      duration={0}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Repeat Purchase Rate</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={0}
                      duration={2.75}
                      suffix="%"
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="analysis_table">
            <div className="analysis_tsp_table">
                <Table dataSource={orderCustomers} columns={coloums} pagination={{ pageSize: 5 }}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};