import {
  IResourceComponentsProps,
  useExport,
  useGetIdentity,
  CrudFilters,
  HttpError,
  useNavigation,
} from "@pankod/refine-core";

import {
  List,
  RefreshButton,
  CreateButton,
  ExportButton,
  Table,
  useTable,
  Space,
  Button,
  EditButton,
  // ViewButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  FormProps,
  Form,
  Input,
  Popconfirm,
  Modal,
  Icon,
  useSelect,
} from "@pankod/refine-antd";
import { ICustomer, ICustomerFilterVariables } from "interfaces";
import { ViewOrderList } from "../viewOrder";
import { Routes, Route, Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import { EditCustomer } from "../edit";
import { EditUser } from "../editUser";
import { useTranslate } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import {
  DELETE_FAVORITE_LIST,
  GET_ALL_CUSTOMERS,
  INSERT_FAVORITE_LISTS,
  QUERY_EXCEL_PRODUCTS,
} from "query";

import {
  CheckCircleOutlined,
  DeleteColumnOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  ReloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Select } from "antd";
import { useNotification } from "@pankod/refine-core";

import { nhost } from "utility";
import * as XLSX from "xlsx";

import AddTypeHead from "./add";
import { CreateFavourite } from "./create";

// import TimezoneSelect from 'react-timezone-select';

const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;

export const FavouriteList: React.FC<IResourceComponentsProps> = () => {
  const userdata = useGetIdentity();
  const route = useNavigation();

  const t = useTranslate();
  const { data: user } = useGetIdentity();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [createEditModalVisible, setEditCreateModalVisible] = useState(false);
  const [EditformSubmitted, setEditFormSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const { open: Open, close: Close } = useNotification();

  const [children, setChildren] = useState([]);

  const userId = user?.metadata?.org_id;

  

  const [favouriteFilters, setFavouriteFilters] = useState<any>([]);

  function handleChange(value: any) {
    console.log(`selected`);
  }
  const [customerId, setCustomerId] = useState<any>();

  const handleSearch = (e: any) => {
    setCustomerId(e);
  };

  const handleOnClear = () => {
    setFavouriteFilters([]);
    setCustomerId(null);
  };

  const { selectProps } = useSelect<any>({
    resource: "customers",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters:[
      {
        field: "ob_org_id",
        operator: 'eq',
        value: userId,
      },
    ],

    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
      {
        field: "ob_org_id",
        operator:"eq",
        value:userId,
      },
    ],
  });

  useEffect(() => {
    if (customerId) {
      setFavouriteFilters([
        {
          field: "id",
          operator: "eq",
          value: customerId,
        },
      ]);
    }
  }, [customerId]);

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    ICustomer,
    HttpError,
    ICustomerFilterVariables
  >({
    resource: "customers",
    initialPageSize: 10,
    permanentSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "favourite_list_count",
        operator: "gt",
        value: 0,
      },
    ],
    permanentFilter: [
      ...favouriteFilters,
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "favourite_list_count",
        operator: "gt",
        value: 0,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "fullname",
        "address",
        "status",
        "created_at",
        "customer_org_id",
        { favorite_lists: ["id", "name"] },
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name } = params;
      filters.push({
        field: "name",
        operator: "contains",
        value: name !== "" ? name : null,
      });
      return filters;
    },
  });

  const [dataSource, setDataSource] = useState<
    readonly ICustomer[] | undefined
  >(undefined);

  const [newTableProps, setNewTableProps] = useState(tableProps);
  const [Allcustomers, setCustomers]: any = useState({});
  const [searchTableProps, setSearchTableProps]: any = useState([]);

  let searchedValue = searchFormProps.form?.getFieldValue("name");

  const getCustomers = async () => {
    GET_ALL_CUSTOMERS()
      .then((response: any) => {
        setCustomers(response);
      })
      .catch((err) => {
        console.log("Error =>", err);
        Open?.({
          key: "error",
          type: "error",
          message: "Error",
          description: `Something Went Wrong: ${err}`,
        });
        setTimeout(() => {
          Close("error");
        }, 5000);
      });
  };
  useEffect(() => {
    getCustomers();
  }, []);

  const allCust = Allcustomers;

  useEffect(() => {
    if (!dataSource?.length) {
      return;
    }

    if (searchedValue) {
      const newDataSource = allCust?.customers?.filter((item: any) =>
        item?.name.toLowerCase().includes(searchedValue.toLowerCase())
      );

      setNewTableProps((prev: any) => ({ ...prev, dataSource: newDataSource }));
      setSearchTableProps(newDataSource);
      return;
    }

    if (searchedValue === "") {
      setSearchTableProps([]);
      setNewTableProps(tableProps);
    }
  }, [searchFormProps?.form?.getFieldValue("name")]);
  useEffect(() => {
    if (!newTableProps.dataSource) {
      setNewTableProps(tableProps);
    }
    if (tableProps.dataSource?.length) {
      setDataSource(tableProps.dataSource);
    }
  }, [newTableProps, tableProps.dataSource?.length]);

  let datacount = tableQueryResult?.data?.total;

  useEffect(() => {
    if (createModalVisible) {
      setFormSubmitted(false);
    }
  }, [createModalVisible]);

  useEffect(() => {
    if (createUserModalVisible) {
      setFormSubmitted(false);
    }
  }, [createUserModalVisible]);

  useEffect(() => {
    if (!formSubmitted) return;
  }, [formSubmitted]);

  useEffect(() => {
    if (createEditModalVisible) {
      setEditFormSubmitted(false);
    }
  }, [createEditModalVisible]);

  useEffect(() => {
    if (!EditformSubmitted) return;
  }, [EditformSubmitted]);

  const handleDelete = async (id: any) => {
    try {
      const data: any = await DELETE_FAVORITE_LIST(id);

      if (data?.data?.delete_favorite_lists?.affected_rows == 1) {
        Open?.({
          key: "success",
          type: "success",
          message: "Success",
          description: `List has been deleted`,
        });
        setTimeout(() => {
          Close("success");
        }, 5000);
        FTableQueryResult?.refetch();
      }

      tableQueryResult.refetch();
    } catch (error) {
      Open?.({
        key: "error",
        type: "error",
        message: "Error",
        description: `Something Went Wrong ${error}`,
      });
      setTimeout(() => {
        Close("error");
      }, 5000);
    }
  };

  const [Id, setId] = useState<any>(null);

  const {
    tableProps: FTableProps,
    sorter: FSorter,
    tableQueryResult: FTableQueryResult,
    searchFormProps: FSearchFormProps,
  } = useTable<ICustomer, HttpError, ICustomerFilterVariables>({
    resource: "favorite_lists",
    permanentFilter: [
      {
        field: "customer_id",
        operator: "eq",
        value: Id,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "is_active",
        { favorite_lists_items: ["id"] },
        "created_at",
      ],
    },
  });

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded && record?.id) {
      setId(record?.id);
    }

    if (!expanded && record?.id) {
      setId(null);
    }

    if (expanded && record?.id && Id && record.id != Id) {
      // if (expanded&&record.id) {
      //   setId(()=>null);

      setId(record.id);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onImportExcel = (event: any) => {
    const files = event.target;

    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (event: any) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const otherRowws = rows?.shift();

      const ModifiedDdata = rows?.map((e: any, i: any) => {        
        return { code: { _eq: e?.[1]?.trim() } };
        // return { code: { _eq: e?.[1]?.trim() }, name: { _eq: e?.[0]?.trim() } };
      });

      event.target.value = "";

      const condition = {
        _or: ModifiedDdata?.filter((e, i) => e?.code?._eq),
        // _or: ModifiedDdata?.filter((e, i) => e?.name?._eq && e?.code?._eq),
      };

      if (condition?._or?.length < 1) {
        Open?.({
          key: "error",
          type: "error",
          message: "No Data Found",
          description: ` Please add data `,
        });
        return setTimeout(() => {
          Close("error");
        }, 5000);
      }
      QUERY_EXCEL_PRODUCTS({ where: condition })
        .then((result: any) => {          
          if (result?.data?.products?.length > 0) {
            return result?.data?.products?.map((e: any) => ({
              product_id: e?.id,
              favorite_list_id: favListId,
              is_active: true,
              ob_org_id: user?.metadata?.org_id,
              quantity: 1,
            }));
          }
        })
        .then((Ids: any) => {
          INSERT_FAVORITE_LISTS({ objects: Ids })
            .then((resss: any) => {
              FTableQueryResult.refetch()
              if (resss?.data?.insert_favorite_lists_items?.affected_rows > 0) {
                Open?.({
                  key: "success",
                  type: "success",
                  message: "Success",
                  description: `${resss?.data?.insert_favorite_lists_items?.affected_rows} List had been uploaded `,
                });

                setTimeout(() => {
                  Close("success");
                }, 5000);
              } else {
                Open?.({
                  key: "success",
                  type: "success",
                  message: "Success",
                  description: `${
                    resss?.data?.insert_favorite_lists_items?.affected_rows || 0
                  } List had been Added`,
                });

                setTimeout(() => {
                  Close("success");
                }, 5000);
              }
            })
            .catch((err) => {
              Open?.({
                key: "success",
                type: "success",
                message: "Success",
                description: `${err} List had been Added`,
              });
            });

          FTableQueryResult.refetch();
        });
    };
    reader.readAsBinaryString(file);
  };

  const Modals = (value: any) => {
    // getPost({ variables: { limit: 10, offset: 10} });

    return (
      <>
        <Button type="primary" onClick={value}>
          Add
        </Button>
        <Modal
          title="Add"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select"
            defaultValue={["a10", "c12"]}
            onChange={handleChange}
          >
            {children}
          </Select>
          ,
        </Modal>
      </>
    );
  };
  const [file, setFile] = useState<any>(null);

  const handleFileUpload = (info: any) => {
    if (info.file.status === "done") {
      setFile(info.file.originFileObj);
    } else if (info.file.status === "error") {
      // handle error case
    }
  };

  const [favListId, setFavListId] = useState();

  const handleFavListId = (e: any, Id: any) => {
    setFavListId(Id);
  };

  const expandedRowRender = (record: any) => {
    // setId(()=>record?.id)

    if (record?.id) {
      return (
        <>
          <Table
            {...FTableProps}
            rowKey="id"
            size="large"
            style={{ width: "100%", background: "white" }}
            pagination={false}
          >
            <Table.Column dataIndex="name" title="Name" />
            <Table.Column
              dataIndex="is_active"
              title="Active"
              render={(val, record) => (val ? "true" : " false")}
            />
            <Table.Column
              dataIndex="items"
              title=" No of items"
              render={(value, record: any) => (
                <span>{record?.favorite_lists_items?.length || 0}</span>
              )}
            />
            <Table.Column
              dataIndex="created_at"
              title="Created At"
              render={(value) => <DateField value={value} format="LLL" />}
              defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
              sorter
            />
            <Table.Column<any>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space wrap>
                    {/* <Link to={`./${record.id}`}>     */}

                    <AddTypeHead
                      Tablerefetch={tableQueryResult?.refetch}
                      refetch={FTableQueryResult?.refetch}
                      Id={record?.id}
                    />

                    {/* <Link to="viewOrder"><Button onClick={handleViewButton}>View</Button></Link> */}
                    <Button
                      // size="small"
                      // shape="round"
                      type="primary"
                      children={<div style={{ display: "flex", alignItems: "center" }}>
                        <EyeOutlined onClick={() => route.push(`./${record.id}`)} style={{ marginRight: "5px" }} />
                        View
                      </div>}
                      icon={false}
                      onClick={(e: any) => {
                        route.push(`./${record.id}`);

                        e?.preventDefault();
                        e?.stopPropagation();
                      }}
                    />
                    <Popconfirm
                      title="Delete?"
                      onConfirm={(e: any) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        handleDelete(record.id);
                      }}
                    >
                      <a style={{ color: "red" }}>
                        <Button
                          // size="small"
                          type="primary"
                          danger
                          children={<div style={{ display: "flex", alignItems: "center" }}>
                        <DeleteOutlined style={{ marginRight: "5px" }} />
                        Delete
                      </div>}
                      icon={false}
                      />
                      </a>
                    </Popconfirm>

                    <form
                      encType="multipart/form-data"
                      onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <div>
                        <Button className="upload-wrap">
                          <Icon type="upload" />
                          <label
                            htmlFor={record?.id}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Upload
                          </label>
                          <input
                            className="file-uploader"
                            style={{ display: "none" }}
                            id={record?.id}
                            onClick={(e) => handleFavListId(e, record?.id)}
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={(e: any) => {
                              onImportExcel(e);
                              e.target.value = "";
                            }}
                          />
                          {/* <span className="upload-text">Upload file</span> */}
                        </Button>
                        {/* <p className="upload-tip">Supports files in .xlsx, .xls format</p> */}
                      </div>
                    </form>
                  </Space>
                );
              }}
            />
          </Table>
        </>
      );
    } else {
      // setId(null)
      return null;
    }
  };

  const getRowKey = (record: any) => {
    // use the specified rowKey function to generate a unique key for each row
    return record["id"];
  };

  const rowExpandable = (record: any) => {
    // console.log("data is",record?.favorite_lists?.length>0)
    // return true
    return record?.favorite_lists?.length > 0 ? true : false;
  };

  return (
    <List
      title="Favourites"
      pageHeaderProps={{
        extra: [
          <Space>
            <Select
              placeholder="Select a customer"
              style={{ width: 250, marginRight: 20, marginTop: 15 }}
              onSelect={handleSearch}
              clearIcon="*"
              allowClear
              value={customerId || null}
              onClear={handleOnClear}
              {...selectProps}
            />
            <RefreshButton
              type="primary"
              style={{ marginTop: "15px" }}
              children={<div style={{ display: "flex", alignItems: "center" }}>
                <ReloadOutlined style={{ marginRight: "5px" }} />
                Refresh
              </div>}
              icon={false}
              onClick={() => tableQueryResult?.refetch()} />
            <CreateFavourite parentRefetch={tableQueryResult?.refetch} />
          </Space>,
        ],
      }}
    >
      <span>Records: {datacount}</span>
      {searchedValue ? (
        <Table {...newTableProps} pagination={false} rowKey="id" size="small">
          <Table.Column dataIndex="name" title="Name" />
          <Table.Column dataIndex="fullname" title="Full Name" />
          <Table.Column dataIndex="address" title="Address" />
          <Table.Column dataIndex="status" title="Status" />
          <Table.Column
            dataIndex="created_at"
            title="Created At"
            render={(value) => <DateField value={value} format="LLL" />}
            defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
            sorter
          />
          <Table.Column<ICustomer>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                {/* <Link to="viewOrder"><Button onClick={handleViewButton}>View</Button></Link> */}
                <Button
                  size="small"
                  type="link"
                  icon={null}
                  // recordItemId={record.id}
                  // resourceName="customers/customer"
                  onClick={() => {
                    localStorage.setItem("id", record.id);
                    // editUserShow(record.id);
                    setEditCreateModalVisible(true);
                  }}
                >
                  User Invite
                </Button>
                <EditButton
                  size="small"
                  type="link"
                  icon={null}
                  recordItemId={record.id}
                  resourceName="customers/customer"
                  // onClick={() => editShow(record.id)}
                />

                <Popconfirm
                  title="Delete?"
                  onConfirm={() => handleDelete(record.id)}
                >
                  <a style={{ color: "red" }}>Delete</a>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
      ) : (
        <Table
          {...tableProps}
          rowKey={getRowKey}
          size="small"
          expandable={{
            expandedRowRender,
            rowExpandable,
            onExpand: handleExpand,
          }}
          expandedRowKeys={Id ? [Id] : []}
        >
          <Table.Column dataIndex="name" title="Name" />
          <Table.Column
            dataIndex="No of Favorites"
            title="No of Favourites"
            render={(val, record: any) => (
              <span>{record?.favorite_lists?.length || 0}</span>
            )}
          />
        </Table>
      )}
      {/* <CreateCustomer
          handleFormSubmitted={handleFormSubmitted}
          closeCreateModal={handleCloseCreateModal}
          createModalVisible={createModalVisible}
          // modalProps={modalProps}
          // formProps={formProps}
        /> */}
      {/* <CreateFavourite/> */}
      <Modal
        visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        footer={null}
        width="27%"
        style={{
          position: "absolute",
          marginTop: "-5%",
          marginLeft: "70%",
          gridRow: "auto",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%" }}>Customer has been removed</span>
        </div>
      </Modal>
      {/* <EditUser modalProps={editUserModalProps} formProps={editUserFormProps} /> */}
      {/* <Routes>
                          <Route index element={<div>Home</div>} />
                          <Route path="customer" element={<FavouriteList/>} />
                          <Route path="viewOrder" element={<ViewOrderList/>} />
                          <Route path="*" element={<div>Anything</div>} />
                      </Routes> */}
    </List>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps} style={{ display: "flex" }}>
      <Form.Item label="Search" name="name">
        <Input
          allowClear
          placeholder="ID, Title, Content, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item style={{ marginLeft: "5px", marginTop: "21px" }}>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
