import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DateField, Icons, } from "@pankod/refine-antd";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { nhost } from "utility";
import {  Button, Badge } from 'antd';
import { useGetIdentity, useNavigation, } from "@pankod/refine-core";
import {  ArrowLeftOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { GET_ALL_CATEGORIES_BY_FAVORITE_LIST_ID, GET_FAVCUSTOMER, GET_FAVITEM_CATEGORIES, GET_FAVLIST, GET_FAVOURITELIST_ITEMS, MUTATE_MULTIPLE_CATEGORIES } from "query";
import { useNotification } from "@pankod/refine-core";
import { UncontrolledTooltip } from "reactstrap";
import { CSVLink } from "react-csv";
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Image } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import "./favourite.css"

export const ViewCategories: React.FC<
  any
> = () => {

  
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;

  const { open, close } = useNotification();

  const { goBack } = useNavigation();
  
  const { id } = useParams();

  const [newData, setNewData] = useState<any>([])

  const [excelRender, setExcelRender] = useState<any>(false)
  
  const [favData, setFavData] = useState<any>([])

  const [categoriesOptions, setCategoriesOptions] = useState<any>([])

  const [existsAllProducts, setExistAllProducts] = useState<any>([]);

  const [favList, setFavList] = useState<any>()

  const [favListCustomer, setFavListCustomer] = useState<any>()

  const [printTable, setPrintTAble] = useState(false);

  const [existAllProductsExporttoExcel, setExistAllProductsExporttoExcel] = useState<any>([]);

  const [PageData, setPageData] = useState({ limit: 100, pageNo: 1, })

  const [count, setCount] = useState<any>();
  
  const [maxPage, setMaxPage] = useState<any>();

  const User:any = nhost.auth.getUser()
  const currectDate = new Date()

  const nextPage = (cur:any) =>{

     setPageData({...PageData, pageNo:PageData?.pageNo+1})

  }
  const prevPage = (cur:any) =>{

    setPageData({...PageData, pageNo:PageData?.pageNo-1})

  }
const getFavlistname = (id:any) => {
  GET_FAVLIST(id).then((res:any) => {
    setFavList(res?.favorite_lists[0])
    GET_FAVCUSTOMER(res?.favorite_lists[0]?.customer_id).then((cus:any) => {
      setFavListCustomer(cus?.customers[0])
    })
  })
}

const getFavItems = async () => {

  const favData:any = await GET_FAVOURITELIST_ITEMS(id)
  setFavData(favData)

  const catData:any = await GET_FAVITEM_CATEGORIES(user?.metadata?.org_id)
  setCategoriesOptions(catData)  

}

  const getInitData = async (cond:Object) =>{

    try {
      getFavItems();
      const Data:any = await GET_ALL_CATEGORIES_BY_FAVORITE_LIST_ID(id, cond)

      setNewData (Data?.categories)
      setCount( Data?.categories_aggregate?.aggregate?.count)
      setMaxPage(Math.ceil(Data?.categories_aggregate?.aggregate?.count / PageData.limit))
      setCount(Data?.favorite_lists_aggregate?.aggregate?.count)
  

    }catch(error:any){

      console.error("Error => ",error)
      open?.({
        key: "error",
        type: "error",
        message: "",
        description: `Something Went Wrong ${error}`,
      });
      setTimeout(() => {
        close("error");
      }, 5000);

     }

  }

   useEffect(()=>{

    const cond = {
                    object: { "is_active":{"_eq":  true}, "ob_org_id":{"_eq":User?.metadata?.org_id} }, 
                    limit:PageData?.limit,
                    offset:(PageData.pageNo - 1) * PageData.limit,    
                 }

      getInitData(cond)
   },[PageData?.limit, PageData?.pageNo])

   useEffect(() => {
    getFavlistname(id);
    getFavItems();
   },[id])
   useEffect(() => {
    getFavItems();
   },[])

   useEffect(() => {
    let existTemp = favData?.favorite_lists_items?.map((data: any, index: any) => {
      
      return ({
        "id": data?.id,
        "key": data?.id,
        "name": data?.product?.name,
        "image": data?.product?.image,
        "quantity": data?.product?.quantity,
        "neededquantity": 0,
        "Product_code": data?.product?.referencecode,
        "stock": data?.product?.quantity,
        "itemTotal": data?.product?.msrp,
        "productId": data?.product_id,
        "sort_by": data?.sort_by,
        "categories": data?.product?.categories
      })
    });
    if (existTemp) {
      setExistAllProducts(existTemp);
    }
  }, [favData])

const uniqueData = existsAllProducts.filter((value: any, index: any, self: any) => {
  return index === self.findIndex((item: any) => (
    item.categories === value.categories && item.categories != null
    ));
  });
  let categoryOrder = categoriesOptions?.categories?.map((cat:any) => {
    return cat?.name
  })
  
  const getCategoryIndex = (categoryName: any) => {
    const index = categoryOrder?.indexOf(categoryName);
    return index === -1 ? Infinity : index;
  };
  
  const sortedCategories = uniqueData.sort(
    (a: any, b: any) => getCategoryIndex(a.categories) - getCategoryIndex(b.categories)
    ); 

  const [rowsChanged, setRowsChanged] = useState(false)
  const route = useNavigation()
  const onDragEnd = (result: any) => {
    // handle drag and drop here
    if (!result.destination) {
      return;
    }

    setRowsChanged(true)

    const items = Array.from(newData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setNewData(items);
  };
 

  const savedData = async () => {
    setExcelRender(false)
    try{
      const orderdData = newData?.map((e: any, i: any) => {
        return { ...e, order: i };
      });
  
      const changedData = orderdData?.map((e: any, i: any) => {
        return {
          where: { id: { _eq: e?.id } },
          _set: { order: e?.order },
        };
      });
  
      const Data:any = await MUTATE_MULTIPLE_CATEGORIES(id,{set:changedData});
    
       setNewData(Data?.update_categories_many?.map((e:any,i:any)=>e?.returning?.[0]))

       open?.({
        key: "success",
        type: "success",
        message: "",

        description: `Order has been updated `,
      });
      getFavItems();
      setTimeout(() => {
        close("success");
        setExcelRender(true)
      }, 5000);
  

    } catch(error){
      open?.({
        key: "error",
        type: "error",
        message: "",
        description: `Something Went Wrong ${error}`,
      });
      setTimeout(() => {
        close("error");
      }, 5000);
    }
  };
  
  const componentRef: any = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current
    });

    const handleDual = () => {
      setPrintTAble(true);
      setTimeout(() => {
        handlePrint();
      }, 2000);
      setTimeout(() => {
        setPrintTAble(false);
      }, 5000);
    }

    const styles = StyleSheet.create({
      page: {
        // flexDirection: 'row',
        backgroundColor: '#E4E4E4'
      },
      section: {
        margin: 5,
        padding: 5,
        flexGrow: 1,
        fontSize: 14,
        lineHeight: 2.0,
      },
      title: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        lineHeight: 1,
        flexDirection: "column",
      },
      content: {
        flexDirection: 'row',
        fontSize:12,
        padding: 5,
      },
      head: {
        display: "flex",
        alignContent: "center"
      },
      Sub_head: {
        fontSize: 15,
        fontWeight: "black",
        // textDecoration: "underline",
      },
      table_head: {
        fontSize: 15,
        fontWeight: "black",
        border: 1,
        textAlign: "center",
        marginTop: 10,
        padding:3,
      }, 
      table_view: {    
        flexDirection: "row",
      },
      table_main: {
        // display: "flex",
        // flexDirection: "row",
        // flexWrap: "wrap",
      },
      table_main_view: {
        // width: "50%",
      },
      table_content: {
        fontSize:12,
        border: 1,
        minWidth: "500px",
        maxWidth: "500px",
        paddingLeft: 10,
        padding: 3,
        // textAlign: "center"
      },
      table_content1: {
        fontSize:12,
        border: 1,
        minWidth: "100px",
        maxWidth: "100px"
      },
      head_text: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "heavy",
        textDecoration: "underline",
      },
      value: {
        flexDirection: "row"
      }
  
    }); 
  
    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.head}>
          <Text style={styles.head_text}>{user.name} Wholesale Order List</Text>
          </View>
          <View style={styles.content}>
            <View style={styles.title}>
              <Text style={styles.content}><Text style={styles.Sub_head}>Name             </Text>: {favListCustomer?.name}</Text>
              <Text style={styles.content}><Text style={styles.Sub_head}>Online Login  </Text>: {favListCustomer?.email}</Text>
            </View>
            <View style={styles.title}>
              <Text style={styles.content}> <Text style={styles.Sub_head}>List Name </Text>: {favList?.name}</Text>
              <Text style={styles.content}> <Text style={styles.Sub_head}>Date          </Text>: {currectDate.getDate()}/{currectDate.getMonth()+1}/{currectDate.getFullYear()}</Text>
            </View>
          </View>
          {/* <View style={styles.table_main}>
            {newData.map((category:any, index:any) => {
              if(category?.products?.length > 0){
              return <View key={index} style={styles.table_main_view}>
                <Text style={styles.table_head}>{category?.name}</Text>
                {existsAllProducts?.map((product:any, i:any) => {
                    return (
                      <View key={i} style={styles.table_view}>
                        <Text style={styles.table_content}>{product?.name}</Text>
                        <Text style={styles.table_content1}> </Text>
                      </View>
                    )
                })}
              </View>
              }
            })}
          </View> */}
           <View style={styles.table_main}>
          {uniqueData.map((category:any, index:any) => (
            <View key={index} style={styles.table_main_view}>
              <Text style={styles.table_head}>{category?.categories}</Text>
              {existsAllProducts?.map((product:any, i:any) => {
                if (category?.categories == product?.categories) {
                  return (
                    <View key={i} style={styles.table_view}>
                      <Text style={styles.table_content}>{product?.name}</Text>
                      <Text style={styles.table_content1}> </Text>
                    </View>
                  )
                }
              })}
            </View>
          ))}
        </View>
        </Page>
      </Document>
    );

  useEffect(() => {
    if(uniqueData?.length > 0 ){
    getFavItems();
    const finalData: any[] = [];
    const processedCategories = new Set();
    
    uniqueData.forEach((data: any) => {
      
      const category = data.categories;

      if (!processedCategories.has(category)) {
        finalData.push(["Category", "Product Name"]);
        finalData.push([category, ""]);
        processedCategories.add(category);
      }
    
      const matchedProducts: any[] = existsAllProducts.filter(
        (product: any) => product.categories === category
      );
    
      matchedProducts.forEach((product: any) => {
        finalData.push(["", product.name]);
      });
    });
    
    let header = []
    header?.push(["", `${user?.name} Wholesale Order List:  `, ""])
    header?.push([])
    header?.push(["Favourite List Name: ",  favList?.name, " ", "Email:  ", favListCustomer?.email])
    header?.push(["Customer Name: ",  favListCustomer?.name, " ", "Date:  ",  `${currectDate.getDate()}/${currectDate.getMonth()+1}/${currectDate.getFullYear()}`])
    header?.push([])
    setExistAllProductsExporttoExcel(header)
    setExistAllProductsExporttoExcel((pre:any) => [...pre, ...finalData])
  }
    // const transformedData = newData.reduce((result:any, newData:any) => {
    //   if (newData.products.length > 0) {
    //     result.push(["Category", "Product Name"]);
    //     result.push([newData.name, ""]);
    
    //     newData.products.forEach((product:any) => {
    //       result.push(["", product.name]);
    //     });
    //   }
    
    //   return result;
    // }, []);
    // let header = []
    // header?.push(["", "Iqbro Wholesale Order List:  ", ""])
    // header?.push([])
    // header?.push(["Favourite List Name: ",  favList?.name, " ", "Email:  ", favListCustomer?.email])
    // header?.push(["Customer Name: ",  favListCustomer?.name, " ", "Date:  ", " "])
    // header?.push([])
    // setExistAllProductsExporttoExcel(header)
    // setExistAllProductsExporttoExcel((pre:any) => [...pre, ...transformedData])
      
    // let tempData: any = [];
    // const transformedData: any = [];
    // let currentCategory: any = null;
    // newData?.map((data: any) => {
    //   data?.products?.forEach((product: any) => {
    //     if (currentCategory !== data.name) {
    //       transformedData.push({ Category: data.name });
    //       currentCategory = data.name;
    //     }

    //     transformedData.push({
    //       "Product Name": product.name,
    //     });
    //     if (transformedData.length > 0) {
    //       setExistAllProductsExporttoExcel((pre: any) => [...transformedData]);
    //     }
    //   });
    // })
    }, [categoriesOptions != null, 
        excelRender == true, 
        uniqueData?.length > 0, 
        favListCustomer,
      ]);

  return (
    <>
    <div className="d-flex justify-content-between">
        <div onClick={() => goBack()} className="d-flex justify-content-start">
          <Button
          type="primary"
          >
            <div className="d-flex justify-content-center align-items-center">
            <ArrowLeftOutlined className="product_back_button me-2" />
            Back

            </div>
          </Button>
        </div>
      <div className="export_icons">
        <div>
          <UncontrolledTooltip
            placement="top"
            target="print"
            className="Tooltip_icons"
          >
            Print
          </UncontrolledTooltip>
          <Image
            width={20}
            height={20}
            src="/images/printer.png"
            alt="printer.png"
            className="fav_Print"
            id="print"
            onClick={() => handleDual()}
          />
          <CSVLink
            data={existAllProductsExporttoExcel}
            onClick={() => {
              console.log("export to excel");
            }}
            filename={`${user?.name}_${favList?.name}`}
          >
            <Image
              width={25}
              height={25}
              src="/images/excel.png"
              alt="excel.png"
              className="fav_Print"
              id="excel"
            />
          </CSVLink>
          <UncontrolledTooltip
            placement="top"
            target="excel"
            className="Tooltip_icons"
          >
            Export to excel
          </UncontrolledTooltip>
          <PDFDownloadLink
            document={<MyDocument />}
            fileName={`${user?.name}_${favList?.name}.pdf`}
          >
            <Image
              width={25}
              height={25}
              className="fav_Print"
              src="/images/pdf.png"
              alt="export.png"
              id="pdf"
            />
          </PDFDownloadLink>
          <UncontrolledTooltip
            placement="top"
            target="pdf"
            className="Tooltip_icons"
          >
            Export to pdf
          </UncontrolledTooltip>
        </div>
        <div>
          {rowsChanged ? (
            <Button
              type="primary"
              onClick={savedData}
              style={{
                padding: "2px 10px",
                float: "right",
                marginLeft: "55px",
              }}
            >
              Save Order
            </Button>
          ) : null}
        </div>
      </div>

    </div>
      {printTable ? (
        <div className="print_content">
          <div ref={componentRef}>
            <h1
              style={{ textAlign: "center", fontSize: "24px", fontWeight: 500,marginTop: "10px" }}
            >
              {user?.name} Wholesale Order List
            </h1>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <h6>
                  {" "}
                  Name{" "}
                  <span style={{ marginLeft: "50px" }}>
                    : {favListCustomer?.name}
                  </span>
                </h6>
                <h6>
                  {" "}
                  Online Login <span>: {favListCustomer?.email}</span>
                </h6>
              </div>
              <div>
                <h6>
                  {" "}
                  List Name <span>: {favList?.name}</span>
                </h6>
                <h6>
                  {" "}
                  Date{" "}
                  <span style={{ marginLeft: "38px" }}>
                    :<span style={{ marginLeft: "8px" }}>{currectDate.getDate()}/</span><span>{currectDate.getMonth()+1}/{currectDate.getFullYear()}</span>
                  </span>
                </h6>
              </div>
            </div>
            <table className="table_main_view">
              {/* {newData.map((category:any) => { 
                        if(category?.products?.length > 0){
                       return <div className='table_coloums'>
                          <tr>
                            <th className='table_col_1'>{category?.name}</th>
                            <th className='table_col_2'></th>
                          </tr>
                            {category?.products?.map((product:any) => {
                                return (
                                  <tr>
                                    <td className='table_col_1'>{product?.name}</td>
                                    <td className='table_col_2'></td>
                                  </tr>
                                )
                            })}
                        </div>

                        }                     
                    })} */}
              {uniqueData.map((category: any, index: any) => (
                <div className="table_coloums">
                  <tr key={index}>
                    <th className="table_col_1">{category?.categories}</th>
                    <th className="table_col_2"></th>
                  </tr>
                  {existsAllProducts?.map((product: any, i: any) => {
                    if (category?.categories == product?.categories) {
                      return (
                        <tr key={i}>
                          <td className="table_col_1">{product?.name}</td>
                          <td className="table_col_2"></td>
                        </tr>
                      );
                    }
                  })}
                </div>
              ))}
            </table>
          </div>
        </div>
       ) : ( 
        <></>
      )}
      <div>{/* <CategoriesAutoComplete/> */}</div>
      <DragDropContext onDragEnd={onDragEnd}>
        {/* <div style={{float:"right"}}>
      {rowsChanged ?  <Button type="primary" onClick={savedData} style={{padding:"2px 10px", float:"right"}}>Save Order</Button> : null}

      </div> */}

        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 10px",
            borderBottomColor: "1px solid black",
          }}
          width="100%"
        >
          <thead>
            <tr style={{ padding: "5px" }}>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Id</th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Name</th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Active</th>
              {/* <th style={{padding:"5px", background:"#f1f1f1"}}>Image</th> */}
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Order</th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Items</th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>
                Created at
              </th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Action</th>
            </tr>
          </thead>
          <Droppable droppableId="characters">
            {(provided) => (
              <tbody
                style={{ padding: "15px" }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {/* <table width="100%" > */}
                {newData?.map((item: any, index: any) => (
                  <Draggable
                    key={item.id}
                    draggableId={String(item?.id)}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        style={{ padding: "15px" }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        onClick={(e) => {
                          console.log("views");
                        }}
                      >
                        <td style={{ padding: "5px" }}>{item.uid}</td>
                        <td style={{ padding: "5px" }}>{item?.name}</td>
                        <td style={{ padding: "5px" }}>
                          {item.is_active ? "true" : "false"}
                        </td>

                        <td style={{ padding: "5px" }}>
                          {item?.order != null ? String(item?.order) : null}
                        </td>

                        <td style={{ padding: "5px" }}>
                          {" "}
                          <Badge
                            style={{ backgroundColor: "#52c41a" }}
                            count={item?.products?.length}
                          />
                        </td>

                        <td style={{ padding: "5px" }}>
                          <DateField value={item.created_at} format="LLL" />
                        </td>
                        <td>
                          {item?.products?.length > 0 ? (
                            <Button
                              style={{
                                color: "green",
                                outline: "none",
                                border: "none",
                              }}
                              onClick={(e: any) => {
                                route.push(`./list/${item.id}`);
                              }}
                              icon={<EyeOutlined />}
                            ></Button>
                          ) : (
                            <Button
                              style={{
                                color: "orange",
                                outline: "none",
                                border: "none",
                                cursor: "auto",
                              }}
                              icon={<EyeInvisibleOutlined />}
                            ></Button>
                          )}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {/* </table> */}
              </tbody>
            )}
          </Droppable>
        </table>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            padding: "10px",
            width: "95%",
            alignItems: "center",
            justifyContent: "flex-end",
            // background:"green"
          }}
        >
          <div>
            <Button
              onClick={() => prevPage(PageData?.pageNo)}
              disabled={PageData.pageNo <= 1}
            >
              Previous
            </Button>
            <Button
              onClick={() => nextPage(PageData?.pageNo)}
              disabled={PageData?.pageNo >= maxPage}
            >
              Next
            </Button>
          </div>
          <span>
            {" "}
            Page{" "}
            <strong>
              {" "}
              {PageData?.pageNo} of {maxPage}{" "}
            </strong>{" "}
          </span>

          <select
            value={PageData?.limit}
            onChange={(e) => {
              const value = e.target.value ? Number(e.target.value) : 100;
              setPageData({ ...PageData, limit: value });
            }}
          >
            {[100, 200, 300, 400, 500].map((size) => (
              <option key={size} value={size}>
                {" "}
                Show {size}{" "}
              </option>
            ))}
          </select>
        </div>
      </DragDropContext>
    </>
  );
};
