import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  InputNumber, Checkbox, useSelect, Select
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import React, { useState, useEffect } from 'react';
import { IOrg } from "interfaces";
import { nhost } from "utility/nhost";
import { CheckCircleOutlined, DeleteOutlined, PictureOutlined } from '@ant-design/icons';
import { UploadProps, Upload, DatePicker, message } from 'antd';
import  "../../store/store.css"
import { jsonConverter } from "components/helpers/jsonConverter";

type CreateBannersProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeCreateModal: () => void
  createModalVisible: boolean,
  handleFormSubmitted: () => void,
  tableQueryResult: any
  viewOrderMax: any
};
const { TextArea } = Input;
const { Dragger } = Upload;
const {TabPane} = Tabs

const onChange = (key: string) => {
};

export const CreateBanner: React.FC<CreateBannersProps> = ({
  modalProps,
  formProps,
  closeCreateModal,
  createModalVisible,
  handleFormSubmitted,
  tableQueryResult,
  viewOrderMax,
}) => {
  const { data: user } = useGetIdentity();
  
  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const [textBoxModal, setTextBoxModal] = useState(false);
  const [urlList, setUrlList] = useState([] as any[]);
  const [mobileUrlList, setMobileUrlList] = useState([] as any[]);
  const [imageList, setImageList] = useState([] as any[]);
  const [closemodel, setCloseModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedBannerType, setSelectedBannerType] = useState(null);
  const [selectedBannerPage, setSelectedBannerPage] = useState(null);
  const [selectedLinkType, setSelectedLinkType] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [headingShow, setHeadingShow] = useState({signup:true, login: true})
  const [urlButtonShow, setURLButtonShow] = useState({signup:true, login: true})

  const props: UploadProps = {
    beforeUpload: (file) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSizeInBytes) {
        message.error('File size exceeds the limit (10MB).');
        return false;
      }
      return true;
    },
    name: 'file',
    multiple: true,
    onChange(info) {
      let data = [];
      for (const file of info.fileList) {
        data.push(file)
      }
      setImageList(data);
    },
    onDrop(e) {
    },
  };

  useEffect(() => {
    formProps?.form?.setFieldsValue({[ `view_order`]:++viewOrderMax})
  },[viewOrderMax])

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setUrlList(urlList => [ data.secure_url]);
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };


  const uploadMobileImages = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "bt5y9hp4");
      data.append("cloud_name", "dov89ugan");
      data.append("folder", "orderbit");

      const response = await fetch("https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
        method: "post",
        body: data,
      });

      const result = await response.json();

      if (response.ok) {
        const transformedUrl = `https://res.cloudinary.com/dov89ugan/image/upload/w_450,h_222,c_fill/${result.public_id}.${result.format}`;

        
        setMobileUrlList(mobileUrlList => [transformedUrl]);
        onSuccess("Ok");
      } else {
        throw new Error(result.error.message);
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const removeImage = () => {
    setUrlList([]);
  }
  
  const removeMobileImage = () => {
    setMobileUrlList([]);
  }

  const handleActiveCheck = () => {
    setTextBoxModal(!textBoxModal);
  };


      
  const resetHeadingProps = (heading: any) => {
    Object.assign(heading, {
      heading_color: null,
      title: null,
    });
  };
  
  const resetLinkProps = (link: any) => {
    Object.assign(link, {
      link_color: null,
      link_text: null,
      link_text_color: null,
      link_type: null,
    });
  };

  const apidata = (e: any) => {
    
    if (headingShow.login) {
      if(e.banner_props.login){
        resetHeadingProps(e.banner_props.login.heading);
      }
    }
    
    if (urlButtonShow.login) {
      if(e.banner_props.login){
        resetLinkProps(e.banner_props.login.link);
          Object.assign(e.banner_props.login,{
            redirect_url: null
          })
      }
    }
    
    if (headingShow.signup) {
      if(e.banner_props.signu){
        resetHeadingProps(e.banner_props.signup.heading);
      }
    }
    
    if (urlButtonShow.signup) {
      if(e.banner_props.signup){
        resetLinkProps(e.banner_props.signup.link);
        Object.assign(e.banner_props.login,{
          redirect_url: null
        })
      }
    }

    let heading = {
      "heading": e.heading ?? null,
      "heading_color": e.heading_color,
    }
    let description = {
      "description": e.description ?? null,
      "description_color": e.description_color,
    }
    let button = {
      "button": e.button ?? null,
      "button_color": e.button_color,
      "button_text_color": e.button_text_color,
    }
    
    const MUTATIONAPI = `mutation MyMutation {
    insert_banners(objects: {  view_order:${e.view_order}, active: ${e.active}, ob_org_id: "${user?.metadata?.org_id}",
    image: ${e.image ? `"${e.image}"` : null}, mobile_image: ${e.mobile_image ? `"${e.moblie_image}"` : null}, banner_props: ${jsonConverter(e.banner_props)},
    }){
      affected_rows
    }
  }
  `
    const imageSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      if (data) {
        // handleFormSubmitted()
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 4000);
        tableQueryResult?.refetch()
        closeCreateModal()
        formProps?.form?.resetFields();
        setUrlList([]);
        setMobileUrlList([])
        return data
      }
    }
    imageSubmit();
  }

  // const closeModal = () => {
  //   setCloseModel(!closemodel);
  // }
  const handleOk = () => {
    setOpen(false)
  }

  const handleBannerPageChange = (value:any) => {
    setSelectedBannerPage(value);
  };

  const handleBannerTypeChange = (value:any) => {
    setSelectedBannerType(value);
  };
  const selectBannerPageOptions = [
    { label: 'Home', value: 'Home' },
    { label: 'Contact', value: 'Contact' },
    { label: 'About', value: 'About' },
    { label: 'FAQ', value: 'FAQ' },
  ];

  const selectHomeOptions = [
    { label: 'Main', value: 'Main' },
    { label: 'Special Banner', value: 'Special Banner' },
    { label: 'Card', value: 'Card' },
    { label: 'Side bar', value: 'Side bar' },
  ];

  const selectOtherPageOptions = [
    { label: 'Main', value: 'Main' },
    { label: 'Side bar', value: 'Side bar' },
  ];
  
  const selectOptions = selectedBannerPage == "Home" ? selectHomeOptions : selectOtherPageOptions;

  const handleLinkTypeChange = (value:any) => {
    setSelectedLinkType(value);
  };
  const linkSelectOptions = [
    { label: 'Button', value: 'Button' },
    { label: 'Link', value: 'Link' },
  ];

  const handleUrlChange = (value:any) => {
    setSelectedUrl(value);
  };
  const urlSelectOptions = [
    { label: 'Category', value: 'category' },
    { label: 'Signin', value: 'signin' },
    { label: 'Favourites', value: 'my-account/favourites' },
    { label: 'Orders', value: 'my-account/orders' },
  ];

  const handleLocationChange = (value:any) => {
    setSelectedLocation(value);
  };
  const locationSelectOptions = [
    { label: 'Below Main banner', value: 'Below Main banner' },
    { label: 'Below Cards', value: 'Below Cards' },
    { label: 'Below Feature Product', value: 'Below Feature Product' },
    { label: 'Below Category', value: 'Below Category' },
    { label: 'Above Footer', value: 'Above Footer' },
  ];

  const sidebarLocationOptions = [
    { label: 'Left', value: 'Left' },
    { label: 'Right', value: 'Right' },
  ]
  

  const handelHead = () => {
    setHeadingShow((prevState) => ({
      ...prevState,
      signup: !prevState.signup,
    }));
  };
  const handelHeadLogin = () => {
    setHeadingShow((prevState) => ({
      ...prevState,
      login: !prevState.login,
    }));
  };
  const handelUrlButton = () =>{
    setURLButtonShow((prevState) =>({
      ...prevState,
      signup: !prevState.signup,
    }))
  }
  const handelUrlButtonLogin = () =>{
    setURLButtonShow((prevState) =>({
      ...prevState,
      login: !prevState.login,
    }))
  }

  return (
    <>
      <Modal {...modalProps} title="Create Banner"
        onCancel={closeCreateModal}
        visible={createModalVisible}
        className="create_banner"
      >
        <Form {...formProps} onFinish={(e) => {
          e.image = urlList.join(",")
          e.mobile_image = mobileUrlList.join(",")
          apidata(e)
        }} size="large" layout="vertical">

          <Form.Item name={['banner_props', 'banner_page']} label="Banner Page"
            rules={[{ required: true, message: 'Please Select Banner Page!' }]}  
          >
            <Select
              onChange={handleBannerPageChange}
              value={selectedBannerPage}
            >
              {selectBannerPageOptions.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="banner_props">
            <Form.Item name={['banner_props', 'banner_type']} label="Banner Type"
                rules={[{ required: true, message: 'Please Select Banner Type!' }]}  
              >
                <Select
                  onChange={handleBannerTypeChange}
                  value={selectedBannerType}
                >
                  {selectOptions?.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                  ))}
                </Select>
            </Form.Item>
        
            {selectedBannerType  != "Special Banner" ? 
            <>
            { selectedBannerType  == "Side bar" ? 
            <>
            <Form.Item
            name={['banner_props', 'side_bar_location']}
            label="Location"
            rules={[{ required: true, message: 'Please Select Side bar Location!' }]}  
          >
            <Select
              onChange={handleLocationChange}
            >
              {sidebarLocationOptions.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          </>
          : <></>
          }
            </>
            : <>
            <Form.Item
              name={['banner_props', 'special_banner_location']}
              label="Location"
              rules={[{ required: true, message: 'Please Select Banner Location!' }]}  
            >
              <Select
                onChange={handleLocationChange}
                value={selectedLocation}
              >
                {locationSelectOptions.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            </> }
          </div>
          <Tabs>
            <TabPane tab="Signup" key={1}>
              <div className="heading">
                <div style={{display: 'flex', alignItems:'center', gap: '20px'}} aria-disabled ={headingShow.signup} >
                  <Form.Item
                    name={['banner_props','signup','heading','title']}
                    label={<span style={{color:headingShow.signup?"gray":""}}>Heading</span>}
                    rules={[{ required:headingShow.signup ? false: true, message: 'Please Enter Heading!' }]}
                    style={{width:'85%'}}
                  >
                    <TextArea rows={1} disabled ={headingShow.signup}/>
                  </Form.Item>
                  <Form.Item  style={{display:'flex', alignItems: 'center', alignSelf: 'flex-end'  }}>
                    <Checkbox onClick={()=>handelHead()}>Show</Checkbox>
                  </Form.Item>
                </div>
                
                <Form.Item
                  name={['banner_props','signup','heading','heading_color']}
                  label={<span style={{color:headingShow.signup?"gray":""}}>Color</span>}                       
                  initialValue={headingShow.signup ? "#080808" : "#808080"}
                >
                  <Input type="color" disabled = {headingShow.signup}/>
                </Form.Item>
              </div>
              {selectedBannerType  != "Card" ? 
              <div className="description">
              <Form.Item
                name={['banner_props','signup','description','description']}
                label="Description"
              >
                <TextArea rows={2} />
              </Form.Item>
              <Form.Item
                name={['banner_props','signup','description','description_color']}
                label="Color"
                initialValue={"#080808"}
              >
                <Input type="color"/>
              </Form.Item>
              </div>
              : <></> }
              {selectedBannerPage == "Home" ? 
              <div className="button">
              <Form.Item
                name={['banner_props','signup','link','link_type']}
                label={<span style={{color:urlButtonShow.signup?"gray":""}}>Link Type</span>}
                rules={[{ required: urlButtonShow.signup? false :true, message: 'Please Select Link Type!' }]}
              >
                <Select
                  onChange={handleLinkTypeChange}
                  value={selectedLinkType}
                  disabled = {urlButtonShow.signup}
                >
                  {linkSelectOptions.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={['banner_props','signup','link','link_text']}
                label={<span style={{color:urlButtonShow.signup?"gray":""}}>Link Name</span>}
                rules={[{ required: urlButtonShow.signup? false :true, message: 'Please Enter Link Name!' }]}
              >
                <TextArea rows={1} disabled = {urlButtonShow.signup}/>
              </Form.Item>
              <Form.Item
                name={['banner_props','signup','link','link_color']}
                label={<span style={{color:urlButtonShow.signup?"gray":""}}>Color</span>}
                initialValue={"#080808"}
              >
                <Input type="color" disabled = {urlButtonShow.signup}/>
              </Form.Item>
              <Form.Item
                name={['banner_props','signup','link','link_text_color']}
                label={<span style={{color:urlButtonShow.signup?"gray":""}}>Text Color</span>}
                initialValue={"#f5f5f5"}
              >
                <Input type="color" disabled = {urlButtonShow.signup}/>
              </Form.Item>
              </div>
              : <></> }
              <div className="others">
                <div>
                {selectedBannerPage == "Home" ? 
                <div style={{display:'flex', justifyItems: 'center', alignItems: 'center', gap: '20px'}}>
                  <Form.Item
                    name={['banner_props','signup','redirect_url']}
                    label={<span style={{color:urlButtonShow.signup?"gray":""}}>Url</span>}
                    style={{color:'gray', width:'100%'}}
                    rules={[{ required: urlButtonShow.signup ? false : true, message: 'Please Select Link Url!' }]}
                  >
                    <Select
                      onChange={handleUrlChange}
                      value={selectedUrl}
                      disabled = {urlButtonShow.signup}
                    >
                      {urlSelectOptions.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{display:'flex', alignItems: 'center', alignSelf: 'flex-end'  }}>
                    <Checkbox onClick={()=>handelUrlButton()}> Show </Checkbox>
                  </Form.Item>
                </div>
                : <></> }
                  
                </div>
              <Form.Item label="" name="active" style={{ display: "flex", alignSelf: "end" }} initialValue={true} valuePropName="checked">
                <Checkbox> Active </Checkbox>
              </Form.Item>
              <Form.Item
                name="view_order"
                label="View Order"
                rules={[{ required: true, message: 'Please input View Order!' }]}
              >
                <InputNumber defaultValue="0" style={{ width: '100%' }} name="view_order" />
              </Form.Item>
              </div>
                {user?.defaultRole === "admin" ?
                  <Form.Item
                    label="Orderbit Organisation"
                    name="ob_org_id"
                    rules={[{}]} >
                    <Select {...OBOrgSelectProps} />
                  </Form.Item> : ""
                }
            </TabPane>
            <TabPane tab="Login" key={2}>
              <div className="heading">
                <div style={{display: 'flex', alignItems:'center', gap: '20px'}} aria-disabled ={headingShow.login} >
                  <Form.Item
                    name={['banner_props', 'login','heading','title']}
                    label={<span style={{color:headingShow.login?"gray":""}}>Heading</span>}
                    rules={[{ required:headingShow.login ? false: true, message: 'Please Enter Heading!' }]}
                    style={{width:'85%'}}
                  >
                    <TextArea rows={1} disabled ={headingShow.login}/>
                  </Form.Item>
                  <Form.Item  style={{display:'flex', alignItems: 'center', alignSelf: 'flex-end'  }}>
                    <Checkbox onClick={()=>handelHeadLogin()}>Show</Checkbox>
                  </Form.Item>
                </div>
                
                <Form.Item
                  name={['banner_props','login', 'heading','heading_color']}
                  label={<span style={{color:headingShow.login?"gray":""}}>Color</span>}                      
                  initialValue={headingShow.login ? "#080808" : "#808080"}
                >
                  <Input type="color" disabled = {headingShow.login}/>
                </Form.Item>
              </div>
              {selectedBannerType  != "Card" ? 
              <div className="description">
              <Form.Item
                name={['banner_props','login', 'description','description']}
                label="Description"
              >
                <TextArea rows={2} />
              </Form.Item>
              <Form.Item
                name={['banner_props','login', 'description','description_color']}
                label="Color"
                initialValue={"#080808"}
              >
                <Input type="color"/>
              </Form.Item>
              </div>
              : <></> }
              {selectedBannerPage == "Home" ? 
              <div className="button">
              <Form.Item
                name={['banner_props','login', 'link','link_type']}
                label={<span style={{color:urlButtonShow.login?"gray":""}}>Link Type</span>}
                rules={[{ required: urlButtonShow.login? false :true, message: 'Please Select Link Type!' }]}
              >
                <Select
                  onChange={handleLinkTypeChange}
                  value={selectedLinkType}
                  disabled = {urlButtonShow.login}
                >
                  {linkSelectOptions.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={['banner_props','login', 'link','link_text']}
                label={<span style={{color:urlButtonShow.login?"gray":""}}>Link Name</span>}
                rules={[{ required: urlButtonShow.login? false :true, message: 'Please Enter Link Name!' }]}
              >
                <TextArea rows={1} disabled = {urlButtonShow.login}/>
              </Form.Item>
              <Form.Item
                name={['banner_props','login', 'link','link_color']}
                label={<span style={{color:urlButtonShow.login?"gray":""}}>Color</span>}
                initialValue={"#080808"}
              >
                <Input type="color" disabled = {urlButtonShow.login}/>
              </Form.Item>
              <Form.Item
                name={['banner_props','login', 'link','link_text_color']}
                label={<span style={{color:urlButtonShow.login?"gray":""}}>Text Color</span>}
                initialValue={"#f5f5f5"}
              >
                <Input type="color" disabled = {urlButtonShow.login}/>
              </Form.Item>
              </div>
              : <></> }
              <div className="others">
                <div>
                {selectedBannerPage == "Home" ? 
                <div style={{display:'flex', justifyItems: 'center', alignItems: 'center', gap: '20px'}}>
                  <Form.Item
                    name={['banner_props','login','redirect_url']}
                    label={<span style={{color:urlButtonShow.login?"gray":""}}>Url</span>}
                    style={{color:'gray', width:'100%'}}
                    rules={[{ required: urlButtonShow.login ? false : true, message: 'Please Select Link Url!' }]}
                  >
                    <Select
                      onChange={handleUrlChange}
                      value={selectedUrl}
                      disabled = {urlButtonShow.login}
                    >
                      {urlSelectOptions.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{display:'flex', alignItems: 'center', alignSelf: 'flex-end'  }}>
                    <Checkbox onClick={()=>handelUrlButtonLogin()}> Show </Checkbox>
                  </Form.Item>
                </div>
                : <></> }
                  
                </div>
              {/* <Form.Item label="" name="active" style={{ display: "flex", alignSelf: "end" }} initialValue={true} valuePropName="checked">
                <Checkbox> Active </Checkbox>
              </Form.Item>
              <Form.Item
                name="view_order"
                label="View Order"
                rules={[{ required: true, message: 'Please input View Order!' }]}
              >
                <InputNumber defaultValue="0" style={{ width: '100%' }} name="view_order" />
              </Form.Item> */}
              </div>
                {user?.defaultRole === "admin" ?
                  <Form.Item
                    label="Orderbit Organisation"
                    name="ob_org_id"
                    rules={[{}]} >
                    <Select {...OBOrgSelectProps} />
                  </Form.Item> : ""}
              
              <Form.Item name="created_by"
                initialValue={user?.id}
              >
                <Input type="hidden" />
              </Form.Item>
            </TabPane>
          </Tabs>
          <div>
            <Form.Item label={selectedBannerType  == "Side bar" ? <div>Upload Desktop Banner Image <pre>(When uploading an image, please ensure it has a <strong> 2:3 </strong> aspect ratio for the best viewing experience)</pre></div> : "Upload Deshbord Banner Image"}
            name="image"
            rules={[{ required: true, message: 'Please Select Image!' }]}
            >
              <div className="Image_div">
                <div>
                  <Dragger showUploadList={false}
                  {...props}
                  name="image" 
                  customRequest={uploadImages}
                  accept="image/*"
                  >
                    <p className="ant-upload-drag-icon">
                      <PictureOutlined />
                    </p>
                    <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                  </Dragger>
                </div>
                <div className="category_img_div">
                  <h1 style={{ fontSize: "14px" }}>Uploaded image will be displayed here</h1>
                  {urlList?.map((url:any) => {
                    return <div>
                    <img className="category_img" src={url} />
                      {url ? <span ><DeleteOutlined onClick={removeImage}/></span> : <></> }
                    </div>
                  })}
                </div>
              </div>
            </Form.Item>
            {selectedBannerType == "Main" &&
              <Form.Item label={<div>Upload Mobile Banner Image <pre>(When uploading an image, please ensure it has a <strong> 450 x 222 </strong> pixel for the best viewing experience)</pre></div>}
                name="mobile_image"
                // rules={[{ required: true, message: 'Please Select Image!' }]}
              >
                <div className="Image_div">
                  <div>
                    <Dragger showUploadList={false}
                    {...props}
                    name="image" 
                    customRequest={uploadMobileImages}
                    accept="image/*"
                    >
                      <p className="ant-upload-drag-icon">
                        <PictureOutlined />
                      </p>
                      <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                    </Dragger>
                  </div>
                  <div className="category_img_div">
                    <h1 style={{ fontSize: "14px" }}>Uploaded image will be displayed here</h1>
                    {mobileUrlList?.map((url:any) => {
                      return <div>
                      <img className="category_img" src={url} />
                        {url ? <span ><DeleteOutlined onClick={removeMobileImage}/></span> : <></> }
                      </div>
                    })}
                  </div>
                </div>
              </Form.Item>
            }
          </div>
        </Form>
      </Modal>
      <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleOk}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully created banner
          </span>
        </div>
      </Modal>
    </>
  );
};
