import { ArrowUpOutlined } from "@ant-design/icons";
import { Empty, Table } from "@pankod/refine-antd";
import { render } from "@testing-library/react";
import { useDashboardContext } from "contaxt/dashboardContext";
import moment from "moment";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import {
  Bar,
  BarChart,
  Cell,
  ComposedChart,
  Legend,
  Line,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

export const OrderInsightChart = () => {
  const {filterDate, orderCustomers, orderData, orderTrends, orderCount, totalRevenue, stackedOrder, topSalesTabale, aov} = useDashboardContext();

  let filterStates = filterDate == null ? true : false

  let conversionRate: number = Number(((orderCustomers?.length/orderCount)*100).toFixed(2));
   
  let cancellationOrder: any = orderData?.nodes?.filter((data: any)=>data?.status === "Cancelled");

  let cancellationRate: any = (cancellationOrder?.length/orderCount)*100

  const coloums: any = [
    {
      dataIndex:"name",
      title: "Product",
    },
    {
      dataIndex:"orderCount",
      title: "Order",
    },
    {
      dataIndex:"revenue",
      title: "Revenue",
      render:(_: any)=>{
        return(
          <p>£ {_.toFixed(2)}</p>
        )
      }
    }
  ] 
  
  const COLORS = ["#0066CB", "#C8190B", "#F3C045", "#4CB040"];

  const middleIndex = Math.floor(orderTrends.length / 2);

  const CustomLegend = (props: any)=>{
    const {payload} = props 
    return(
      <div className="legend_continer">
        <div className="legend_box">
          {payload?.map((item: any)=>{
            return(
              <div className="legend_card">
                <div className="legend_card_body">
                  <div className="legend_indicator">
                    <span className="indicator_color" style={{backgroundColor: `${item?.color}`}}></span>
                    <p className="indicator_txt">{item?.dataKey}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  
  const CustomPieLegend = (props: any)=>{
    const {payload} = props 
    return(
      <div className="legend_pip_continer">
        <div className="legend_pip_box">
          {payload?.map((item: any)=>{
            
            return(
              <div className="legend_pip_card">
                <div className="legend_pip_card_body">
                  <div className="legend_pip_indicator">
                    <span className="indicator_color" style={{backgroundColor: `${item?.color}`}}></span>
                    <p className="indicator_txt">{item?.value}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const totalValue = stackedOrder.reduce((acc: number, curr: any) => acc + curr.value, 0);

  return (
    <div className="continer">
      <div className="analysis_card">
        <div className="analysis_card-header">
          <div className="card_title">
            <h4>Order Insight</h4>
          </div>
        </div>
        <div className="analysis_card-body">
          <div className="analysis_report_box">
            <div className="report_card">
              <div className="report_title">
                <p>Total Order</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={orderCount}
                      duration={2.6}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Total Revenue</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={totalRevenue}
                      prefix="£ "
                      duration={2.75}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Average Order Value</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={aov}
                      prefix="£"
                      duration={2.75}
                      decimal="2"
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Conversion Rate</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={conversionRate}
                      duration={2.75}
                      suffix="%"
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    <p className="report_rate">0.0%</p>
                  </div>
                {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Cancellation Rate</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                  <CountUp
                    start={0}
                    end={cancellationRate}
                    suffix="%"
                    duration={2.75}
                    className="countup_count"
                  />
                  <ArrowUpOutlined className="arrow" />
                  <p className="report_rate">0.0%</p>
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="analysis_chart">
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart data={orderTrends} 
                margin={{ top: 20, left: 0 }}
              >
                <Legend content={CustomLegend}/>
                <Tooltip content={CustomTooltip}/>
                <XAxis 
                width={200} 
                dataKey="date" />
                <YAxis tickFormatter={(value) => `£ ${value}`}/>

                <Bar dataKey="orderCount" fill="#8884d8" />
                <Line
                  type="monotone"
                  dataKey="orderLine"
                  stroke="#ff7300"
                  strokeWidth={2}
                  dot={false}
                />

              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="analysis_top_sale_card">
        <div className="top_sale_card_wrapper">
          <div className="top_sales_tba">
            <h3>Sales Order</h3> 
            <Table dataSource={topSalesTabale} columns={coloums} pagination={{ pageSize: 5 }}/>
          </div>
          <div className="stacked_bar_card">
            <h3>Sales</h3>
            <ResponsiveContainer width="100%" height="90%">
            {totalValue === 0 ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
              <Empty/>
              </div>
            ) :(
              <PieChart >
                <Legend  content={<CustomPieLegend/>}/>
                <Tooltip content={<CustomPieTooltip/>}/>
                <Pie
                  data={stackedOrder}
                  // cx={120}
                  cy={160}
                  innerRadius={120}
                  outerRadius={140}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {stackedOrder.map((entry: any, index: any) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            ) }
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomPieTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
  
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip_continer">
        {/* <div className="tooltip_header">
          <p>{`${label}`}</p>
        </div> */}
        <div className="tooltip_body">
          {payload.map((entry, index) => (
            <div className="tooltip_content">
              <div>
                <span style={{backgroundColor: entry.payload.fill}} className="tooltip_indicator"></span>
              </div>
              <div className="tooltip_indicator-box">
                <p className="tooltip_indicator-lable">{entry.name}: </p>
                <p className="tooltip_indicator-lable"> {entry.value}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
  
  if (active && payload && payload.length) {
    
    return (
      <div className="custom-tooltip_continer">
        <div className="tooltip_header">
          <p>{`${label}`}</p>
        </div>
        <div className="tooltip_body">
          {payload.map((entry, index) => {
            return(
            <div className="tooltip_content">
              <div>
                <span style={{backgroundColor: entry?.color}} className="tooltip_indicator"></span>
              </div>
              <div className="tooltip_indicator-box">
                <p className="tooltip_indicator-lable">{entry.name}: </p>
                <p className="tooltip_indicator-lable"> {entry.value}</p>
              </div>
            </div>
          )})}
        </div>
      </div>
    );
  }

  return null;
};
