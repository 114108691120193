import {useEffect, useState} from 'react';
import { DeleteOutlined, MinusCircleOutlined, PictureOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal, Col, Row,
    Checkbox, useSelect, Select, DatePicker, Button, Space,Image
  } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import type { UploadProps } from 'antd';
import { Upload, message } from 'antd';
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

type CreateFaqProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    viewOrderMax:any
    close:any
  };

const props: UploadProps = {
  beforeUpload: (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      message.error('File size exceeds the limit (10MB).');
      return false;
    }
    return true;
  },
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {        
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        return info?.file?.response
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
    },
  };

  const { Dragger } = Upload;
  const { TextArea } = Input;

export const CreateAboutus: React.FC<CreateFaqProps> = ({
    modalProps,
    formProps,
    viewOrderMax,
    close,
  }) => {
    const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write",);
  const [urlList, setUrlList] = useState([] as any[]);
  const [imageChange, setImageChange] = useState<any>(false);

  useEffect(() => {
    formProps?.form?.resetFields();
    setUrlList([]);
    formProps?.form?.setFieldsValue({[ `view_order`]:++viewOrderMax})
  },[viewOrderMax])

  const uploadImages = (name:any) => async (options:any) => {    
    const { onSuccess, onError, file } = options;
    setImageChange(false)
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "bt5y9hp4");
      data.append("cloud_name", "dov89ugan");
      data.append("folder", "orderbit");
  
      fetch("https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          setUrlList((urlList) => [...urlList, data.secure_url]);     
          setImageChange(true)     
          return onSuccess(data.secure_url);
        })
        .catch((err) => {
          console.log(err);
          onError({ err });
        });
    } catch (err) {
      console.log(err);
      onError({ err });
    };
  };

  const removeImage = (index:any) => {
    const updatedUrlList = urlList.filter((_, i) => i !== index);
  setUrlList(updatedUrlList);
  }  

return (
  <Modal {...modalProps} title="Create About Us Content"
  afterClose={() => {
      formProps?.form?.resetFields()
      setUrlList([])
    formProps?.form?.setFieldsValue({[ `view_order`]:++viewOrderMax})
  }}
  >
    <Form {...formProps} size="large" layout="vertical">
      <Form.Item
        label="Title"
        name="title"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Content"
        name="content"
      >
        <ReactMde
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          generateMarkdownPreview={(markdown: any) =>
            Promise.resolve(
              <ReactMarkdown>{markdown}</ReactMarkdown>,
            )
          }
        />
      </Form.Item>
      <Form.Item label="" name="is_show" initialValue={true} valuePropName="checked">
        <Checkbox> Show </Checkbox>
      </Form.Item>
      <Form.Item
        label="View Order"
        name="view_order"
      >
        <Input type="number" min="0"  />
      </Form.Item>
      <h6>Content Banners</h6>
      <div>
        {/* <Form.Item
          label="Content Below"
          name={["content_banners", "content_below"]}
        >
          <TextArea />
        </Form.Item> */}
        <Form.List name={['content_banners', 'images']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <Space key={key}>
                  <Form.Item
                    label="Image"
                    // name={[name, 'image_url']}
                    rules={[{ required: true, message: 'Please upload an image!' }]}
                  >
                    <div>
                      <Dragger
                        showUploadList={false}
                        {...props}
                        customRequest={uploadImages(name)}
                        disabled={urlList[name]}
                        accept="image/*"
                      >
                        <p className="ant-upload-drag-icon">
                          <PictureOutlined />
                        </p>
                        <p className="ant-upload-text">Drag image file here to upload (or Click)</p>
                      </Dragger>
                    </div>
                  </Form.Item>

                  <Form.Item
                  >
                    <p style={{ fontSize: '14px' }}>Uploaded image will be displayed here</p>
                    <div style={{ display: "flex", justifyContent: "center" }}><img style={{ width: "100px", height: "100px" }} src={urlList[name]} /></div>
                  </Form.Item>
                  {urlList[name]  || imageChange ?
                    <Form.Item
                      label="df"
                      name={[name, 'image_url']}
                      initialValue={urlList[name]}
                      style={{ display: "none" }}
                    >
                      <Input />
                    </Form.Item>
                    : <></>}

                  <Form.Item
                    label="View Order"
                    name={[name, 'view_order']}
                    // initialValue={name + 1}
                  >
                    <Input type="number" min="0"  />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => {
                    remove(name)
                    removeImage(name)
                  }} />
                </Space>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()} icon={false}>
                <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Image
                        </div>
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

      </div>
      {user ?
        <>
          <Form.Item
            name="created_by"
            initialValue={user?.id}
          >
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="ob_org_id"
            initialValue={userId}
          >
            <Input type="hidden" />
          </Form.Item>
        </>
        : <></>}
    </Form>
  </Modal>
)
}